<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { watch, toRefs, ref } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';

const emit = defineEmits(['close-modal']);
const { storeStaffRequirement, updateStaffRequirement } = useStaffStore();
const { requirementOptions } = storeToRefs(useOptionsStore());
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    staffRequirement: {
        type: Object,
        default: () => ({
            //
        })
    },
    staff: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const { staffRequirement } = toRefs(props);
const { staff } = toRefs(props);

const form = ref({
    //
});

watch(() => staffRequirement, (staffRequirement) => {
    if (props.mode === 'Create') {
        return;
    }

    form.value = {
        requirement: requirementOptions.value.find(req => req.value == staffRequirement.value.pivot.requirement_id),
        issue_date: staffRequirement.value.pivot?.issue_date ? parseLocalDateFromString(staffRequirement.value.pivot.issue_date) : null,
    };
}, { immediate: true });

const save = async () => {
    const action = props.mode === 'Create' ? storeStaffRequirement : updateStaffRequirement;
    
    await action({
        staff_hash: staff?.value?.hash,
        requirement_hash: staffRequirement?.value?.hash, // for updating, this is the hash of the requirement (for route-model binding)
        requirement: form.value?.requirement, // for create, this is the ID of the requirement
        issue_date: form.value.issue_date ? formatDate(form.value.issue_date) : null,
    });

    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Staff Training'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'Staff Training'}) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-3 gap-x-4">
            <StyledDropdown
                v-model="form.requirement"
                :disabled="mode === 'Edit'"
                :placeholder="$t('label.requirement_options')"
                :options="requirementOptions"
                name="requirement"
                :label="$t('label.requirement_options')" />
            <StyledDatepicker   
                v-model="form.issue_date"
                :placeholder="$t('label.issue_date')"
                name="issue_date"
                :label="$t('label.issue_date')" />
        </div>
    </form>
    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
