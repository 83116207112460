<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';
import { useSupportPlanStore } from '@/stores/supportPlan';

// Stores
const { supportPlanTypeOptions, supportPlanStatusOptions } = storeToRefs(useOptionsStore());
const { update } = useSupportPlanStore();

// Utils
const toast = useToast();
const { formatDOB } = useDateFormatter();

const props = defineProps({
    supportPlan: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    //
});

watch(() => props.supportPlan, (plan) => {
    form.value = {
        type_id: plan?.type_id ?? null,
        start_date: plan?.start_date ? formatDOB(plan?.start_date) : null,
        review_date: plan?.review_date ? formatDOB(plan?.review_date) : null,
        status_id: plan?.status_id ?? null,
    };
}, { immediate: true });

const save = () => {
    update({
        hash: props.supportPlan.hash,
        ...form.value,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};
</script>

<template>
<div class="sm:grid sm:grid-cols-1 lg:grid-cols-2 lg:w-2/3 gap-4 p-2">
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg mb-4">{{ $t('label.support_plan_details') }}</div>
    <StyledDropdown
        v-model="form.type_id"
        :placeholder="$t('label.type')"
        :options="supportPlanTypeOptions"
        name="type_id"
        :label="$t('label.type')" />
    <StyledDatepicker
        v-model="form.start_date"
        :placeholder="$t('label.start_date')"
        name="start_date"
        type="text"
        :label="$t('label.start_date')" />
    <StyledDatepicker
        v-model="form.review_date"
        :placeholder="$t('label.review_date')"
        name="review_date"
        type="text"
        :label="$t('label.review_date')" />
    <StyledDropdown
        v-model="form.status_id"
        :placeholder="$t('label.status')"
        :options="supportPlanStatusOptions"
        name="status_id"
        :label="$t('label.status')" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>
</div>
</template>
