import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useTimelineStore = defineStore('timeline', () => {
    const all = ref({ data: [] });
    const active = ref(null);

    const reset = () => {
        all.value = { data: [] };
    };

    const searchClientTimeline = (params) => {
        return axios.get(`/api/v1/timeline/client/${params.hash}`, { params });
    };

    const searchProgramTimeline = (params) => {
        return axios.get(`/api/v1/timeline/program/${params.hash}`, { params });
    };

    const searchStaffTimeline = (params) => {
        return axios.get(`/api/v1/timeline/staff/${params.hash}`, { params });
    };   

    const storeTimeline = (params) => {
        return axios.post('/api/v1/timeline/', params);
    };   

    const updateTimeline = (params) => {
        return axios.patch(`/api/v1/timeline/${params.hash}`, params);
    };

    const getTimelineOptions = () => {
        return axios.get('/api/v1/timeline/options');
    };

    return {
        active,
        reset,
        all,
        searchClientTimeline,
        searchProgramTimeline,
        searchStaffTimeline,
        storeTimeline,
        updateTimeline,
        getTimelineOptions
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useTimelineStore, import.meta.hot));
}
