import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useApplicationStore = defineStore('application', () => {
    const all = ref({ data: [] });
    const active = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const update = async (params) => {
        return axios.patch(`/api/v1/application/${params.hash}`, params);
    };

    return {
        active,
        all,
        reset,
        update,
    };
});
