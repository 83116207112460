<script>
import { format, parse } from 'date-fns';

export function useDateFormatter() {
    const formatDateTime = (date, formatString = 'yyyy-MM-dd h:mm a') => {
        return date ? format(new Date(date), formatString) : '';
    };

    const formatDate = (date, formatString = 'yyyy-MM-dd') => {
        return date ? format(new Date(date), formatString) : '';
    };

    const parseDate = (dateString) => {
        return parse(dateString, 'yyyy-MM-dd', new Date());
    };
    
    const parseLocalDateFromString = (date) => {
        if (date instanceof Date) {
            throw new Error('Date object is not allowed - use a string');
        }
        try {
            const [year, month, day] = date.split('-').map(Number);
            return new Date(year, month - 1, day);
        } catch (error) {
            console.error('Error parsing date:', error);
            return null;
        }
    };

    const formatDOB = (date) => {
        if (typeof date !== 'string') {
            throw new Error('Date must be a string');
        }
        try {
            const [datePart] = date.split('T');
            return parseLocalDateFromString(datePart); 
        } catch (error) {
            console.error('Error formatting DOB:', error);
            return null;
        }
    };

    return {
        formatDateTime,
        formatDate,
        parseDate,
        parseLocalDateFromString,
        formatDOB,
    };
}
</script>
