import { useUserStore } from '@/stores/user';

const requestInterceptor = [
    request => {
        const { locale } = useUserStore();
        if (locale) {
            request.headers['Accept-Language'] = locale;
        }
        return request;
    },
];
export default {
    request: requestInterceptor,
};
