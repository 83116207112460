const requirements = {
    name: 'Requirements',
    path: '/requirements',
    icon: 'mdi-file-document',
    label: 'Requirements',
};

const horizon = {
    name: 'Horizon',
    path: '/horizon',
    icon: 'mdi-sun-angle',
    label: 'Horizon',
    private: true,
    privateLabel: 'SA'
};

const pulse = {
    name: 'Pulse',
    path: '/pulse',
    icon: 'mdi-pulse',
    label: 'Pulse',
    private: true,
    privateLabel: 'SA'
};

const telescope = {
    name: 'Telescope',
    path: '/telescope',
    icon: 'mdi-telescope',
    label: 'Telescope',
    private: true,
    privateLabel: 'SA'
};

const staff = {
    name: 'Staff',
    path: '/staff',
    icon: 'mdi-account-group',
    label: 'Staff'
};

const programs = {
    name: 'Programs',
    path: '/programs',
    icon: 'mdi-home',
    label: 'Programs'
};

const clients = {
    name: 'Clients',
    path: '/clients',
    icon: 'mdi-account-group',
    label: 'Clients'
};

const templates = {
    name: 'Templates',
    path: '/templates',
    icon: 'mdi-file-document',
    label: 'Templates'
};

export const superAdmin = _ => {
    return [
        staff,
        programs,
        clients,
        requirements,
        templates
    ];
};

export const programCoordinator = _ => {
    return [
        programs,
        clients,
        templates
    ];
};

export const clientSupportWorker = _ => {
    return [
        clients,
        templates
    ];
};

export const programDirector = _ => {
    return [
        programs,
        clients,
        templates
    ];
};

export const organizationAdmin = _ => {
    return [
        staff,
        programs,
        clients,
        requirements,
        templates
    ];
};

export const bottomNavRoutes = _ => {
    return [
        horizon,
        pulse,
        telescope,
    ];
};
