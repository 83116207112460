import { NProgress } from '@/utils/NProgress';

const requestInterceptor = [
    request => {
        NProgress.start();
        return request;
    },
    error => {
        NProgress.done();
        return Promise.reject(error);
    }
];

const responseInterceptor = [
    response => {
        NProgress.done();
        return response;
    },
    error => {
        NProgress.done();
        return Promise.reject(error);
    }
];

export default {
    request: requestInterceptor,
    response: responseInterceptor,
};
