<script setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useClientStore } from '@/stores/client';
import { useOptionsStore } from '@/stores/options';
import { router } from '@inertiajs/vue3';

const { searchClientSupportPlan } = useClientStore();
const { clientSupportPlans } = storeToRefs(useClientStore());
const { supportPlanStatusOptions, supportPlanTypeOptions } = storeToRefs(useOptionsStore());

import { inject } from 'vue';
const modals = inject('modals');

const props = defineProps({
    client: {
        type: Object,
        default: () => null
    }
});

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
    status: null, 
    type: null, 
});

const fetchSupportPlans = () => {
    searchClientSupportPlan({
        ...params.value,
        hash: props.client.hash,
    });
};

const openSupportPlanModal = (supportPlan) => {
    modals.show('SupportPlanModal', {
        props: {
            mode: 'Create',
            plan: supportPlan,
            client: props.client,
        }
    }).then(() => {
        fetchSupportPlans();
    });
};

const accessSupportPlan = (supportPlan) => {
    router.visit(`/clients/support-plan/${supportPlan.hash}`);
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    fetchSupportPlans();
};

</script>

<template>
<div>
    <div class="flex justify-end pb-4">
        <StyledButton @click="openSupportPlanModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_support_plan') }}
        </StyledButton>
    </div>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledDropdown
            v-model="params.status"
            clearable
            :placeholder="$t('label.status')"
            :options="supportPlanStatusOptions"
            name="status"
            :label="$t('label.status')" 
            @input="fetchSupportPlans" />
        <StyledDropdown
            v-model="params.type"
            :placeholder="$t('label.type')"
            :options="supportPlanTypeOptions"
            name="type"
            :label="$t('label.type')" 
            @input="fetchSupportPlans" />
    </div>
    
    <DataTable
        v-if="clientSupportPlans && clientSupportPlans.data"
        :data="clientSupportPlans"
        :headers="[
            { title: $t('label.start_date'), key: 'start_date', value: item => `${item.start_date.split('T')[0]}` },
            { title: $t('label.review_date'), key: 'review_date', value: item => `${item.review_date.split('T')[0]}` },
            { title: $t('label.status'), key: 'status', value: item => `${item.status.name}` },
            { title: $t('label.type'), key: 'type', value: item => `${item.type.name}` },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessSupportPlan(item),
            },
        ]" 
        @table-update="tableUpdate" />
    <div v-else>
        {{ $t('label.no_support_plans') }}
    </div>
</div>
</template>
