//Documentation: https://v3.vue-final-modal.org/api
import { $vfm } from 'vue-final-modal';
import ModalBase from '@/modals/ModalBase';

export default class Modals {
    show(name, options) {
        return new Promise((resolve, reject) => {
            const { props } = options ?? {};
            $vfm.show({
                component: ModalBase,
                bind: {
                    name: name,
                    ...props,
                },
                slots: {
                    default: {
                        bind: {
                            ...props,
                        },
                        component: name,
                        on: {
                            closeModal(success = true) {
                                $vfm.hide(name);
                                resolve(success);
                            },
                            cancelModal(error = 'Modal Cancelled') {
                                $vfm.hide(name);
                                reject(error);
                            },
                        },
                    },
                }
            });
        });
    }

    hide(name) {
        $vfm.hide(name);
    }

    hideAll() {
        $vfm.hideAll();
    }
}
