<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useDocumentStore } from '@/stores/document';
import { useToast } from 'vue-toastification';
import { trans } from 'laravel-vue-i18n';
const toast = useToast();

const props = defineProps({
    document: {
        type: Object,
        default: () => ({
            user_id: null,
            content: '',
            note: '',
            file: null,
        }),
    },
    documentableType: {
        type: String,
        default: null,
    },
    documentableId: {
        type: Number,
        default: null,
    },
});

const emit = defineEmits(['close-modal']);
const { store } = useDocumentStore();

const file = ref(null);
const note = ref(null);

const save = () => {
    const formData = new FormData();
    formData.append('note', note.value);
    formData.append('file', file.value);
    formData.append('documentable_type', props.documentableType);
    formData.append('documentable_id', props.documentableId);

    store(formData)
        .then(() => {
            toast.success(trans('label.saved'));
            emit('close-modal');
        })
        .catch(() => {
            toast.error(trans('label.error'));
        });
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t("label.document") }}</template>
    <template #subheader>{{
        $t("label.create", { what: "Document" })
    }}</template>
    <v-form @submit.prevent="save">
        <StyledFileSelector
            v-model="file"
            name="file"
            :label="$t('label.file')"
            show-loading
            outlined
            dense
            @change="(e) => (file.value = e)" />
        <StyledTextArea
            v-model="note"
            class="mt-2"
            name="note"
            show-loading
            :label="$t('label.note')"
            outlined
            dense />
    </v-form>
    <template #footer>
        <v-btn show-loading :text="$t('label.upload_file')" @click="save" />
    </template>
</ModalWrapper>
</template>
