import { hydratePinia } from '../utils/hydratePinia';

const responseInterceptor = [
    response => {
        hydratePinia(response.data);
        return response;
    },
];

export default {
    response: responseInterceptor,
};
