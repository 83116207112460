import { reactive } from 'vue';
import { defineStore } from 'pinia';

export const useErrorStore = defineStore('error', () => {
    const all = reactive({});

    const add = (errors) => {
        Object.assign(all, errors);
    };

    const addToKey = (key, error) => {
        all[key] = error;
    };

    const remove = (key) => {
        if (all[key]) {
            delete all[key];
        }
    };

    const reset = () => {
        for (const key in all) {
            delete all[key];
        }
    };

    return {
        all,
        add,
        addToKey,
        remove,
        reset,
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useErrorStore.acceptHMRUpdate);
}
