import { useErrorStore } from '@/stores/error';

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const importStateData = (key, state) => {
    // Formatted store name. Example: useUserStore
    const storeName = 'use' + capitalize(key) + 'Store';

    // Import store dynamically
    import(`../stores/${key}.js`).then((module) => {
        const store = module[storeName]();

        // Loop through state and apply to store
        for (const [key, value] of Object.entries(state)) {
            // Check if key exists in store before applying
            if (store[key] !== undefined) {
                store[key] = value;
            }
        }
    }).catch((error) => {
        console.error(`Error dynamically importing store ${storeName}: ${error}`);
    });
};

export function hydratePinia(props) {
    let vuex = (props.vuex ?? props.$vuex) ?? null;

    if (props.errors) {
        useErrorStore().add(props.errors);
    }

    if (vuex) {
        // check if props is string
        const stateProps = typeof vuex === 'string'
            ? JSON.parse(vuex)
            : vuex;

        // Get state from props
        for (const [key, { state }] of Object.entries(stateProps?.modules ?? {})) {
            importStateData(key, state);
        }
    }
};
