<script setup>
import { ref, inject, onMounted } from 'vue';
import { useDocumentStore } from '@/stores/document';
import { storeToRefs } from 'pinia';

import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
const toast = useToast();

const { all } = storeToRefs(useDocumentStore());

const {
    searchApplicationDocuments,
    searchProgramDocuments,
    searchSupportPlanDocuments,
    searchTimelineDocuments,
    archive
} = useDocumentStore();

const props = defineProps({
    documentable: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
});

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    dateFrom: null,
    dateTo: null,
    per_page: 10,
});

const searchDocuments = () => {
    let action = null;

    switch (props.type) {
    case 'timeline':
        action = searchTimelineDocuments;
        break;
    case 'program':
        action = searchProgramDocuments;
        break;
    case 'support-plan':
        action = searchSupportPlanDocuments;
        break;
    case 'application':
        action = searchApplicationDocuments;
        break;
    }

    if (!action) {
        console.error('No action found for document type');
    }

    action({
        ...params.value,
        hash: props.documentable.hash,
    });
};

onMounted(async () => {
    await searchDocuments();
});

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchDocuments();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, {
        page,
        sort_by: sortBy,
        per_page: itemsPerPage,
    });
    searchDocuments();
};

// ====================================================
//        Form Actions
// ====================================================
const selectNewDocument = async () => {
    await modals
        .show('DocumentModal', {
            props: {
                documentableType: props.type,
                documentableId: props.documentable.id
            },
        });
    searchDocuments();
};

const downloadDocument = (document) => {
    window.open(`/api/v1/documents/${document.hash}`, '_blank');
};

const archiveDocument = async (document) => {
    const confirmation = await modals.show('ConfirmationModal', {
        props: {
            prompt: trans('label.are_you_sure_delete_form'),
        },
    });

    if (confirmation) {
        try {
            await archive(document.hash);
            searchDocuments();
            toast.success(trans('label.file_archived'));
        } catch (_) {
            toast.error(trans('label.error'));
        }
    }

};
</script>

<template>
<MainContentLayout>
    <template #title>{{ $t("label.documents") }}</template>
    <template #actions>
        <StyledButton
            @click="selectNewDocument"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{ $t("label.new_document") }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchDocuments"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDatepicker
            v-model="params.dateFrom"
            :label="$t('label.date_from')"
            view-mode="month"
            name="date_from"
            @input="searchDocuments" />
        <StyledDatepicker
            v-model="params.dateTo"
            :disabled="!params.dateFrom"
            :label="$t('label.date_to')"
            view-mode="month"
            name="date_to"
            @input="searchDocuments" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            {
                title: $t('label.filename'),
                key: 'original_filename',
                value: (item) => item.original_filename,
                sortable: false,
            },
            {
                title: $t('label.user'),
                key: 'title',
                value: (item) => `${item.user.staff.first_name} ${item.user.staff.last_name}`,
                sortable: false,
            },
            {
                title: $t('label.note'),
                key: 'note',
                value: (item) => item.note,
                sortable: false,
            },
            {
                title: $t('label.mime'),
                key: 'type',
                value: (item) => item.mime_type.replace('application/', '').replace('image/', '').toUpperCase(),
                sortable: false,
            },
            {
                title: $t('label.created_at'),
                key: 'created_at',
                value: (item) => item.created_at.split('T')[0],
                sortable: false,
            },
        ]"
        :actions="[
            {
                title: $t('label.download'),
                icon: 'mdi-download',
                action: (item) => downloadDocument(item),
            },
            {
                title: $t('label.archive'),
                icon: 'mdi-delete-forever-outline',
                action: (item) => archiveDocument(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
