import { useErrorStore } from '@/stores/error';

const responseInterceptor = [
    // Skip all 2xx responses
    response => {
        useErrorStore().reset();
        return response;
    },
    error => {
        if (error?.response?.data?.errors) {
            // Automatically commit all validation errors to pinia
            useErrorStore().add(error.response.data.errors);
        }
        return Promise.reject(error);
    }
];
export default {
    response: responseInterceptor,
};
