import * as errorInterceptors from '@/interceptors/error';
import * as loadingInterceptors from '@/interceptors/loading';
import * as nProgressInterceptors from '@/interceptors/nProgress';
import * as piniaInterceptors from '@/interceptors/piniaHydrator';
import * as toastInterceptors from '@/interceptors/toast';
import * as localeInterceptors from '@/interceptors/locale';

const importInterceptor = async function(interceptor, axios) {

    // Apply request interceptors if present
    if (interceptor.default?.request) {
        axios.interceptors.request.use(...interceptor.default.request);
    }

    // Apply response interceptors if present
    if (interceptor.default?.response) {
        axios.interceptors.response.use(...interceptor.default.response);
    }
};

export default async function(options = { axios: window.axios }) {
    const axios = options.axios;

    importInterceptor(errorInterceptors, axios);
    importInterceptor(loadingInterceptors, axios);
    importInterceptor(nProgressInterceptors, axios);
    importInterceptor(piniaInterceptors, axios);
    importInterceptor(toastInterceptors, axios);
    importInterceptor(localeInterceptors, axios);
}
