<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useProgramStore } from '@/stores/program';
import { useOptionsStore } from '@/stores/options';
import { useDateFormatter } from '@/composables/dateFormatter';
import { computed } from 'vue';
const { formatDate, parseLocalDateFromString } = useDateFormatter();

import TimeframeSelector from '@/components/TimeframeSelector';

const props = defineProps({
    program: {
        type: Object,
        default: () => ({
            'is_active': true,
            'timeframes': [],
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const { store, update } = useProgramStore();
const { programTypes } = useOptionsStore();

const form = ref({
    ...props.program,
    type: programTypes.find(type => type.value === props?.program?.type?.id)?.value || null,
    start_date: props?.program?.start_date ? parseLocalDateFromString(props.program.start_date) : null,
    end_date: props?.program?.end_date ? parseLocalDateFromString(props.program.end_date) : null,
    timeframes: []
});

const isOffSite = computed(function() {
    const offSiteId = programTypes.find(type => type.label === 'Off Site').value;
    return form.value.type === offSiteId;
});

const save = async () => {
    const action = props.mode === 'Create' ? store : update;
    await action({
        ...form.value,
        start_date: formatDate(form.value.start_date),
        end_date: formatDate(form.value.end_date),
    });

    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.program') }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { 'what': 'Program' }) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 gap-4">
            <StyledInput
                v-model="form.name"
                name="name"
                :label="$t('label.title')"
                required
                :placeholder="$t('label.program_name')" />
            <StyledDropdown
                v-model="form.type"
                :placeholder="$t('label.type')"
                :options="programTypes"
                name="type"
                :label="$t('label.type')" />
            <StyledTextArea
                v-model="form.description"
                :label="$t('label.description')"
                class="col-span-full"
                name="description" />
            <StyledRoundSwitch
                v-model="form.is_active"
                color="primary"
                inset
                name="is_active"
                :label="$t('label.active')" />
            <v-divider class="col-span-full my-4" />
            <div class="col-span-full mb-4">{{ $t('label.program_timeframe') }}</div>
            <StyledDatepicker
                v-model="form.start_date"
                :placeholder="$t('label.start_date')"
                name="start_date"
                :range="false"
                :clearable="false"
                :label="$t('label.start_date')" />         
            <StyledDatepicker
                v-model="form.end_date"
                :placeholder="$t('label.end_date')"
                name="end_date"
                :range="false"
                :clearable="false"
                :label="$t('label.end_date')" />
            <TimeframeSelector 
                class="col-span-full" 
                @timeframes-updated="form.timeframes = $event" />
            <v-divider class="col-span-full my-4" />
            <div class="col-span-full mb-4">{{ $t('label.contact_info') }}</div>
            <StyledInput
                v-model="form.contact_name"
                name="contact_name"
                :label="$t('label.contact_name')"
                required
                :placeholder="$t('label.contact_name')" />    
            <StyledInput
                v-model="form.contact_email"
                name="contact_email"
                email
                :label="$t('label.contact_email')"
                required
                :placeholder="$t('label.contact_email')" />   
            <StyledMask
                v-model="form.contact_phone"
                mask="###-###-####"
                :placeholder="$t('label.contact_phone')"
                name="contact_phone"
                :label="$t('label.contact_phone')" />
            <v-divider v-if="isOffSite" class="col-span-full my-4" />
            <div v-if="isOffSite" class="col-span-full mb-4">{{ $t('label.location_info') }}</div>
            <StyledInput
                v-if="isOffSite"
                v-model="form.location_name"
                name="location_name"
                :label="$t('label.location_name')"
                :placeholder="$t('label.location_name')" />
            <StyledInput
                v-if="isOffSite"
                v-model="form.location_address"
                name="location_address"
                :label="$t('label.location_address')"
                :placeholder="$t('label.location_address')" />
            <StyledInput
                v-if="isOffSite"
                v-model="form.location_city"
                name="location_city"
                :label="$t('label.location_city')"
                :placeholder="$t('label.location_city')" />
            <StyledMask
                v-if="isOffSite"
                v-model="form.location_postal"
                name="location_postal"
                :placeholder="$t('label.location_postal')"
                :options="{ mask: '@#@-#@#' }"
                :label="$t('label.location_postal')" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
