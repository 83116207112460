<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { watch, ref } from 'vue';
import { useProgramStore } from '@/stores/program';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['close-modal']);

const { createProgramTask, updateProgramTask } = useProgramStore();

const props = defineProps({
    task: {
        type: Object,
        default: () => ({
            name: '',
            outline: '',
            outcome_fufilment: '',
            supply_requirement: ''
        })
    },
    program: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const { program } = storeToRefs(props);

const form = ref({
    name: '',
    outline: '',
    outcome_fufilment: '',
    supply_requirement: ''
});

watch(() => props.task, (newTask) => {
    if (props.mode === 'Create' || !newTask) {
        form.value = {
            program_hash: program.value.hash,
            name: '',
            outline: '',
            outcome_fufilment: '',
            supply_requirement: ''
        };
    } else {
        form.value = {
            program_hash: program.value.hash,
            task_hash: props.task.hash, 
            name: newTask.name || '',
            outline: newTask.outline || '',
            outcome_fufilment: newTask.outcome_fufilment || '',
            supply_requirement: newTask.supply_requirement || ''
        };
    }
}, { immediate: true });

const save = async () => {
    const action = props.mode === 'Create' ? createProgramTask : updateProgramTask;

    await action({
        ...form.value
    });

    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Task'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'Task'}) }}</template>

    <form @submit.prevent="save">
        <div class="grid grid-cols-1 gap-x-4">
            <StyledInput
                v-model="form.name"
                :placeholder="$t('label.task_name')"
                name="task_name"
                :label="$t('label.task_name')" />

            <StyledTextArea
                v-model="form.outline"
                :placeholder="$t('label.outline')"
                name="outline"
                :label="$t('label.outline')" />

            <StyledTextArea
                v-model="form.outcome_fufilment"
                :placeholder="$t('label.outcome_fufilment')"
                name="outcome_fulfilment"
                :label="$t('label.outcome_fufilment')" />

            <StyledTextArea
                v-model="form.supply_requirement"
                :placeholder="$t('label.supply_requirement')"
                name="supply_requirement"
                :label="$t('label.supply_requirement')" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
