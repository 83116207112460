import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useProgramStore = defineStore('program', () => {
    const all = ref({ data: [] });
    const programTasks = ref({});

    const active = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const search = (params) => {
        return axios.get('/api/v1/programs', { params });
    };

    const store = (params) => {
        return axios.post('/api/v1/programs', params);
    };

    const update = (params) => {
        return axios.patch('/api/v1/programs/' + params.hash, params);
    };

    const destroy = (params) => {
        return axios.delete('/api/v1/programs/' + params.hash);
    };

    const searchProgramParticipants = (params) => {
        return axios.get(`/api/v1/programs/${params.hash}/participants`, { params });
    };

    const updateProgramParticipants = (params) => {
        return axios.patch(`/api/v1/programs/${params.hash}/participants`, params);
    };

    const searchProgramTasks = (params) => {
        return axios.get(`/api/v1/programs/${params.hash}/tasks`, { params });
    };

    const createProgramTask = (params) => {
        return axios.post(`/api/v1/programs/${params.program_hash}/tasks`, params);
    };

    const updateProgramTask = (params) => {
        return axios.patch(`/api/v1/programs/${params.program_hash}/tasks/${params.task_hash}`, params);
    };

    const updateProgramDetails = (params) => {
        return axios.patch('/api/v1/programs/' + params.hash + '/details', params);
    };

    const updateProgramSafety = (params) => {
        return axios.patch('/api/v1/programs/' + params.hash + '/safety', params);
    };

    return {
        active,
        all,
        reset,
        search,
        store,
        update,
        destroy,
        updateProgramDetails,
        updateProgramSafety,
        updateProgramParticipants,
        programTasks,
        createProgramTask,
        updateProgramTask,
        searchProgramTasks,
        searchProgramParticipants
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProgramStore, import.meta.hot));
}
