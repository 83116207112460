import { router } from '@inertiajs/vue3';
import { NProgress } from './utils/NProgress';

router.on('start', () => NProgress.start());
router.on('finish', (event) => {
    if (event.detail.visit.completed) {
        NProgress.done();
    } else if (event.detail.visit.interrupted) {
        NProgress.set(0);
    } else if (event.detail.visit.cancelled) {
        NProgress.done();
        NProgress.remove();
    }
});
