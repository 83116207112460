import { format } from 'date-fns';

const getTemplateCreatedBy = (template) => {
    const originalTemplateVersion = template.original_template_version;
    const createdBy = originalTemplateVersion.created_by;
    const staff = createdBy.staff;
    return `${staff.first_name} ${staff.last_name}`;
};

const getTemplateCreatedAt = (template) => {
    const originalTemplateVersion = template.original_template_version;
    const formattedDate = format(new Date(originalTemplateVersion.created_at), 'yyyy-MM-dd hh:mm:ss a');
    return formattedDate;
};

const getTemplateVersionInfoEditedBy = (template) => {
    const versionInfo = template.version_info;
    const staff = versionInfo.created_by.staff;
    return `${staff.first_name} ${staff.last_name}`;
};

const getTemplateVersionInfoEditedAt = (template) => {
    const versionInfo = template.version_info;
    const formattedDate = format(new Date(versionInfo.created_at), 'yyyy-MM-dd hh:mm:ss a');
    return formattedDate;
};

export {
    getTemplateCreatedBy,
    getTemplateCreatedAt,
    getTemplateVersionInfoEditedBy,
    getTemplateVersionInfoEditedAt
};
