<script setup>
import { onMounted, ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { computed } from 'vue';

// Initialize days of the week and timeframes
const daysOfWeek = [
    trans('common.days.monday'),
    trans('common.days.tuesday'),
    trans('common.days.wednesday'),
    trans('common.days.thursday'),
    trans('common.days.friday'),
    trans('common.days.saturday'),
    trans('common.days.sunday'),
];

const daysOfWeekOptions = computed(() => {
    // return days of week filtered out if it exists in timeframes
    return daysOfWeek.filter(day => !timeframes.value.some(timeframe => timeframe.day_of_week === day));
});

// Initialize time options
// This will generate time options in 15 minute intervals
// e.g. 12:00 AM, 12:15 AM, 12:30 AM, 12:45 AM, 01:00 AM, 01:15 AM, ...
const timeOptions = computed(() => {
    const options = [];
    const pad = (num) => (num < 10 ? '0' : '') + num;

    for (let hour = 0; hour < 24; hour++) {
        for (let minutes = 0; minutes < 60; minutes += 15) {
            const labelHour = hour % 12 === 0 ? 12 : hour % 12;
            const labelMinutes = pad(minutes);
            const period = hour < 12 ? 'AM' : 'PM';
            const label = `${labelHour}:${labelMinutes} ${period}`;
            const value = `${pad(hour)}:${labelMinutes}:00`;

            options.push({ 
                label: label, 
                value: value 
            });
        }
    }

    return options;
});

const props = defineProps({
    initialTimeframes: {
        type: Array,
        default: () => []
    }
});

const timeframes = ref([{
    start_time: null,
    end_time: null,
    day_of_week: null
}]);

onMounted(() => {
    timeframes.value = props.initialTimeframes;
});

// define emit
const emit = defineEmits(['timeframes-updated']);
watch(timeframes, (newVal) => {
    emit('timeframes-updated', newVal);
}, { deep: true });

const addTimeframe = () => {
    timeframes.value.push({ 
        start_time: null, 
        end_time: null, 
        day_of_week: null 
    });
};

const removeTimeframe = (index) => {
    timeframes.value.splice(index, 1);
};

</script>

<template>
<div>
    <div v-for="(timeframe, index) in timeframes" :key="index" class="grid grid-cols-1 mb-5 md:grid-cols-4 md:mb-1 gap-4">
        <StyledDropdown 
            v-model="timeframe.day_of_week"
            :options="daysOfWeekOptions"
            :label="$t('label.day')"
            :name="`timeframes.${index}.day_of_week`" />
        <StyledDropdown
            v-model="timeframe.start_time"
            :options="timeOptions"
            :label="$t('label.start_time')"
            :name="`timeframes.${index}.start_time`" />
        <StyledDropdown
            v-model="timeframe.end_time"
            :options="timeOptions"
            :name="`timeframes.${index}.end_time`" />
        <div class="flex gap-2 text-red-500 mt-1">
            <v-icon 
                icon="mdi-trash-can-outline" 
                :disabled="timeframes.length === 1" 
                class="hidden md:flex text-black w-1/4" 
                @click="removeTimeframe(index)" />
            <StyledButton 
                color="accent" 
                class="md:hidden" 
                @click="removeTimeframe(index)">
                {{ $t('label.remove_timeframe') }}
            </StyledButton>
        </div>
    </div>
    <StyledButton @click="addTimeframe">{{ $t('label.add_timeframe') }}</StyledButton>
</div>
</template>
