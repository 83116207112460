<script setup>
import { ref, watch, computed } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useSupportPlanGoalEventStore } from '@/stores/supportPlanGoalEvent';
import { useOptionsStore } from '@/stores/options';
import { useDateFormatter } from '@/composables/dateFormatter';

// Stores
const { timeOptions, programOptions } = useOptionsStore();
const { updateSupportPlanGoalEvent } = useSupportPlanGoalEventStore();

// Utils
const toast = useToast();
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    event: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    //
});

watch(() => props.event, (event) => {
    if (event) {
        form.value = {
            ...event,
            date: parseLocalDateFromString(props.event.date) ?? new Date()
        };
    }
}, { immediate: true });

const supportPlanDefinedOptions = computed(() => {
    if (!props.event?.support_plan_goal?.defined_options) {
        return [];
    }

    return props.event.support_plan_goal.defined_options.map(option => ({
        label: option.defined_criteria_name,
        value: option.id
    }));
});

const save = () => {

    updateSupportPlanGoalEvent({
        ...form.value,
        date: formatDate(form.value.date),
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};
</script>

<template>
<div class="md:grid md:grid-cols-2 md:gap-4 lg:w-2/3">
    <div class="col-span-full text-lg mb-2 md:mb-4">{{ $t('label.event_details') }}</div>
    <StyledInput
        :value="props.event?.support_plan_goal?.description"
        :disabled="true"
        :placeholder="$t('label.goal_description')"
        name="description" />

    <StyledDatepicker
        v-model="form.date"
        :placeholder="$t('label.date')"
        name="date"
        view-mode="month"
        :label="$t('label.date')" />
    <StyledDropdown
        v-model="form.time"
        :options="timeOptions"
        :label="$t('label.time')"
        name="time" />
    <StyledDropdown
        v-model="form.program_id"
        searchable
        :options="programOptions"
        :label="$t('label.programs')"
        name="programs" />
    <StyledInput
        v-if="props.event?.support_plan_goal?.type.name == 'Measurable Criteria'"
        v-model="form.measurable_goal_criteria_value"
        :placeholder="$t('label.goal_unit')"
        name="measurable_goal_criteria_value"
        :label="$t('label.goal_unit')" />
    <StyledDropdown
        v-if="props.event?.support_plan_goal?.type.name == 'Defined Criteria'"
        v-model="form.support_plan_defined_option_id"
        clearable
        :placeholder="$t('label.criteria_value')"
        :options="supportPlanDefinedOptions"
        name="support_plan_defined_option_id"
        :label="$t('label.criteria_value')" />
    <StyledInput
        v-model="form.description"
        :placeholder="$t('label.description')"
        name="description"
        :label="$t('label.description')" />
    <StyledDropdown
        v-model="form.was_breakthrough"
        :options="[{ label: $t('label.breakthrough'), value: true }, { label: $t('label.regression'), value: false }]"
        :label="$t('label.flag')"
        name="was_breakthrough" />

    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>
</div>
</template>
