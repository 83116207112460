<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useProgramStore } from '@/stores/program';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import TimeframeSelector from '@/components/TimeframeSelector';
import { useDateFormatter } from '@/composables/dateFormatter';

// Stores
const { updateProgramDetails } = useProgramStore();
const { programTypes } = storeToRefs(useOptionsStore());
const { formatDOB } = useDateFormatter();

// Utils
const toast = useToast();

const isOffSite = ref(false);

const props = defineProps({
    program: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    name: '',
    status: null,
    type: null,
    duration: '',
    day_of_week: '',
    start_date: '',
    end_date: '',
    location_name: '',
    location_address: '',
    location_city: '',
    location_postal: '',
    description: '',
    timeframes: []
});

watch(() => props.program, (program) => {
    form.value = {
        name: program?.name ?? '',
        status: program?.is_active ? true : false,
        type: null,
        duration: program?.duration ?? '',
        start_date: program?.start_date ? formatDOB(program?.start_date) : null,
        end_date: program?.end_date ? formatDOB(program?.end_date) : null,
        location_name: program?.location_name ?? '',
        location_address: program?.location_address ?? '',
        location_city: program?.location_city ?? '',
        location_postal: program?.location_postal ?? '',
        description: program?.description ?? '',
        timeframes: program?.timeframes ?? ''
    };
}, { immediate: true });

watch(programTypes, (types) => {
    form.value.type = types.find(type => type.value === props.program?.type_id).value ?? null;
});

watch(() => form.value.type, () => {
    const offSiteType = programTypes.value.find(type => type.label === 'Off Site');
    isOffSite.value = form.value.type === offSiteType?.value;
}, { immediate: true });

const save = () => {
    updateProgramDetails({
        hash: props.program.hash,
        ...form.value,
        is_active: form.value.status,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};

</script>

<template>
<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />

    <StyledInput
        v-model="form.name"
        :placeholder="$t('label.program_name')"
        name="name"
        type="text"
        :label="$t('label.program_name')" />
    
    <StyledDropdown
        v-model="form.status"
        :placeholder="$t('label.status')"
        :options="[
            { label: $t('label.active'), value: true },
            { label: $t('label.inactive'), value: false },
        ]"
        name="status"
        :label="$t('label.status')" />

    <StyledDropdown
        v-model="form.type"
        :placeholder="$t('label.type')"
        :options="programTypes"
        name="type"
        :label="$t('label.type')" />
    
    <StyledInput
        v-model="form.duration"
        disabled
        :placeholder="$t('label.duration')"
        name="duration"
        type="text"
        :label="$t('label.duration')" />

    <StyledDatepicker
        v-model="form.start_date"
        :clearable="false"
        :placeholder="$t('label.start_date')"
        name="start_date"
        :label="$t('label.start_date')" />

    <StyledDatepicker
        v-model="form.end_date"
        :clearable="false"
        :placeholder="$t('label.end_date')"
        name="end_date"
        :label="$t('label.end_date')" />

    <v-divider v-if="isOffSite" class="col-span-full mb-4" />
    <div v-if="isOffSite" class="col-span-full text-lg mb-4">{{ $t('label.timeframes') }}

        <TimeframeSelector 
            v-if="props.program.timeframes"
            class="col-span-full mt-4" 
            :initial-timeframes="props.program.timeframes" 
            @timeframes-updated="form.timeframes = $event" />

    </div>

    <v-divider v-if="isOffSite" class="col-span-full mb-4" />
    <div v-if="isOffSite" class="col-span-full text-lg mb-4">{{ $t('label.location_information') }}

        <StyledInput
            v-model="form.location_name"
            class="mt-4"
            :placeholder="$t('label.location_name')"
            name="location_name"
            type="text"
            :label="$t('label.location_name')" />

        <StyledInput
            v-model="form.location_address"
            :placeholder="$t('label.location_address')"
            name="location_address"
            type="text"
            :label="$t('label.location_address')" />

        <StyledInput
            v-model="form.location_city"
            :placeholder="$t('label.location_city')"
            name="location_city"
            type="text"
            :label="$t('label.location_city')" /> 

        <StyledInput
            v-model="form.location_postal"
            :placeholder="$t('label.location_postal')"
            name="location_postal_code"
            type="text"
            :label="$t('label.location_postal')" />

    </div>
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg">{{ $t('label.program_information') }}</div>
    
    <StyledTextArea
        v-model="form.description"
        class="col-span-full"
        :placeholder="$t('label.description')"
        name="description"
        :label="$t('label.description')" />

    <v-divider class="col-span-full mb-4" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>
</div>
</template>
