import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useSupportPlanStore = defineStore('supportPlan', () => {
    const all = ref({ data: [] });
    const active = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const search = async (params) => {
        return axios.get('/api/v1/supportplan', { params });
    };

    const store = (params) => {
        return axios.post('/api/v1/supportplan', params);
    };

    const update = async (params) => {
        return axios.patch(`/api/v1/supportplan/${params.hash}`, params);
    };

    const destroy = (params) => {
        return axios.delete('/api/v1/supportplan/' + params.hash);
    };

    return {
        active,
        all,
        reset,
        search,
        store,
        update,
        destroy,
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSupportPlanStore, import.meta.hot));
}
