import * as config from '/theme.js';
const tailwindMaterialColorPack = config.colorConfig;

export default {
    colors: {
        primary: tailwindMaterialColorPack.primary.DEFAULT,
        secondary: tailwindMaterialColorPack.secondary.DEFAULT,
        success: tailwindMaterialColorPack.success.DEFAULT,
        info: tailwindMaterialColorPack.info.DEFAULT,
        accent: tailwindMaterialColorPack.accent.DEFAULT,
        error: tailwindMaterialColorPack.error.DEFAULT,
        warning: tailwindMaterialColorPack.warning.DEFAULT,
        background: tailwindMaterialColorPack.grey['200'],
    }
};
