<script setup>
import { computed } from 'vue';

const props = defineProps({
    timelineable: {
        type: Object,
        required: true
    },
    type: {
        type: String,
        required: true
    }
});

const getTitle = computed(() => {
    switch (props.type) {
    case 'client':
        return `Client: ${props.timelineable.first_name} ${props.timelineable.last_name}`;
    case 'program':
        // eslint-disable-next-line no-case-declarations
        const maxLength = 15;
        // eslint-disable-next-line no-case-declarations
        const programName = props.timelineable.name;
        // eslint-disable-next-line no-case-declarations
        const truncatedLabel = programName.length > maxLength ? `${programName.substring(0, maxLength)}...` : programName;
        return `Program: ${truncatedLabel}`;
    case 'staff':
        return `Staff: ${props.timelineable.first_name} ${props.timelineable.last_name}`;
    }

    return '';
});

const getLink = computed(() => {
    switch (props.type) {
    case 'client':
        return `/clients/${props.timelineable.hash}/details`;
    case 'program':
        return `/programs/${props.timelineable.hash}/details`;
    case 'staff':
        return `/staff/${props.timelineable.hash}/details`;
    }

    return '';
});

</script>
<template>
<a :href="getLink" class="text-blue-600 underline">{{ getTitle }}</a>
</template>

