<script setup>
import { ref, inject } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';

const { search } = useStaffStore();
const { all } = storeToRefs(useStaffStore());

const params = ref({
    term: '',
    status: null,
    page: 1,
    sort_by: null,
    per_page: 10,
    role: null,
});

const searchStaff = () => {
    search({
        ...params.value,
    });
};

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchStaff();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchStaff();
};

const openModal = (modal, mode = 'Create', staff = null) => {
    modals.show(modal, {
        props: staff ? {
            staff: staff,
            mode: mode
        } : {
            mode: mode,
        }
    }).then(() => {
        searchStaff();
    });
};

const accessStaff = (staff) => {
    router.visit(`/staff/${staff.hash}/details`);
};

import { useOptionsStore } from '@/stores/options';
const { roleOptions } = useOptionsStore();

</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.staff_management') }}</template>
    <template #actions>
        <StyledButton @click="openModal('StaffModal')"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_staff') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchStaff"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            clearable
            :placeholder="$t('label.status')"
            :options="[
                { label: $t('label.active'), value: true },
                { label: $t('label.inactive'), value: false },
            ]"
            name="status"
            @input="searchStaff" />
        <StyledDropdown
            v-model="params.role"
            clearable
            :placeholder="$t('label.role')"
            :options="roleOptions"
            name="role"
            @input="searchStaff" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'full_name', value: item => `${item.first_name} ${item.last_name}`, sortable: false },
            { title: $t('label.email'), key: 'email', value: item => item.user.email, sortable: false },
            { title: $t('label.phone'), key: 'phone', value: item => item.phone, sortable: false },
            { title: $t('label.type'), key: 'type', value: item => item.user.roles.length ? item.user.roles[0].display_name : '', sortable: false},
            { title: $t('label.status'), key: 'status', value: item => item.user.is_active ? $t('label.active') : $t('label.inactive'), sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessStaff(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
