// To be reused in components/stores to check if the user has a specific role
const hasRole = (profile, roleName) => profile?.roles?.some(role => role.name === roleName);

export const isRole = (profile) => ({
    superAdmin: () => hasRole(profile, 'super_admin'),
    programCoordinator: () => hasRole(profile, 'program_coordinator'),
    clientSupportWorker: () => hasRole(profile, 'client_support_worker'),
    programDirector: () => hasRole(profile, 'program_director'),
    organizationAdmin: () => hasRole(profile, 'organization_admin'),
});
