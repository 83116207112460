import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useSupportPlanGoalStore = defineStore('supportPlanGoal', () => {
    const all = ref({ data: [] });
    const active = ref({});
    const supportPlanGoals = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const searchSupportPlanGoals = async (params) => {
        return axios.get(`/api/v1/supportplan/${params.hash}/goals`, { params });
    };

    const storeSupportPlanGoal = (params) => {
        return axios.post('/api/v1/supportplan/goal/', params);
    };

    const updateSupportPlanGoal = (params) => {
        return axios.patch(`/api/v1/supportplan/goal/${params.hash}`, params);
    };

    const destroy = (params) => {
        return axios.delete('/api/v1/goals/' + params.hash);
    };

    return {
        active,
        all,
        reset,
        storeSupportPlanGoal,
        updateSupportPlanGoal,
        destroy,
        searchSupportPlanGoals,
        supportPlanGoals,
    };
}
);
