<script>
/**
   * A component that can create a
   * horizontally centered column
   * @name 'StyledCluster'
   * @version 1.0.0
   */
import { computed, defineComponent, h } from 'vue';
import * as Styles from '@css/compositions/StyledCluster.module.scss';

export default defineComponent({
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        columns: {
            type: [Number, String],
            default: null,
        },
    },
    setup(props, { slots }) {
        const classes = computed(() => {
            const classList = Object.values(Styles);
           
            return classList;
        });

        const style = computed(() => {
            const styles = {};
            if (props.columns) {
                styles['grid-template-columns'] = `repeat(${props.columns}, minmax(0, 1fr))`;
            }
            return styles;
        });

        return () => h(props.tag, { class: classes.value, style: style.value }, slots.default());
    },
});
</script>
