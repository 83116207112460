import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', () => {
    const get = ref({});
    const post = ref({});
    const patch = ref({});
    const put = ref({});
    const deleteOp = ref({});

    const isLoading = (loader, method = 'get') => {
        const methodRef = { get, post, patch, put, delete: deleteOp }[method];
        return methodRef.value[loader] > 0;
    };

    const anyLoading = computed(() => {
        return [get, post, patch, put, deleteOp].some(methodRef =>
            Object.values(methodRef.value).some(count => count > 0)
        );
    });

    const remove = (loader, method = 'get') => {
        const methodRef = { get, post, patch, put, delete: deleteOp }[method];
        if (methodRef.value[loader]) {
            methodRef.value[loader]--;
        }
    };

    const add = (loader, method = 'get') => {
        const methodRef = { get, post, patch, put, delete: deleteOp }[method];
        if (!methodRef.value[loader]) {
            methodRef.value[loader] = 0;
        }
        methodRef.value[loader]++;
    };

    return {
        get,
        post,
        patch,
        put,
        delete: deleteOp,
        isLoading,
        anyLoading,
        remove,
        add
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useLoaderStore.acceptHMRUpdate);
}
