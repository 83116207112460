<script setup>
import { ref, inject, onMounted } from 'vue';
import { useFormStore } from '@/stores/form';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const { all } = storeToRefs(useFormStore());
const { templateTypes } = storeToRefs(useOptionsStore());

import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
const toast = useToast();

import { useUserStore } from '@/stores/user';
const { profile } = storeToRefs(useUserStore());

const {
    // Keep these for future reference
    // searchClientForms,
    // searchSupportPlanForms,
    // searchSupportPlanGoalEventForms,
    searchIntakeTourForms,
    searchApplicationForms,
    searchTimelineForms,
    getFormOptions,
    generateFormFromTemplate,
    deleteForm,
} = useFormStore();

const props = defineProps({
    formable: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
});

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    dateFrom: null,
    dateTo: null,
    per_page: 10,
});

const searchForms = () => {
    let action = null;

    switch (props.type) {
    case 'timeline':
        action = searchTimelineForms;
        break;
    case 'intake-tour':
        action = searchIntakeTourForms;
        break;
    case 'application':
        action = searchApplicationForms;
        break;
    }

    if (!action) {
        console.error('No action found for form type');
    }

    action({
        hash: props.formable.hash,
        ...params.value,
    });
};

// When we load the component, we want to search for the forms
onMounted(async () => {
    await getFormOptions();
    await searchForms();
});

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchForms();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, {
        page,
        sort_by: sortBy,
        per_page: itemsPerPage,
    });
    searchForms();
};

// ====================================================
//        Form Actions
// ====================================================
const viewForm = (form) => {
    window.open(`/forms/${form.hash}/fill-out`, '_blank');
};

const download = (form) => {
    window.open(`/api/v1/forms/${form.hash}/download`, '_blank');
};

const destroyForm = async (form) => {
    const confirmation = await modals.show('ConfirmationModal', {
        props: {
            prompt: trans('label.are_you_sure_delete_form'),
        },
    });

    if (confirmation) {
        try {
            await deleteForm(form);
            toast.success(trans('label.form_deleted'));
            searchForms();
        } catch (_) {
            toast.error(trans('label.error'));
        }
    }
};

const canDownload = (item) => {
    return item.template.role_rules.some(({ role_id, can_view }) => role_id === profile.value.roles[0].id && can_view);
};

const canView = (item) => {
    return item.template.role_rules.some(({ role_id, can_view }) => role_id === profile.value.roles[0].id && can_view);
};

const selectNewForm = async () => {
    modals
        .show('TemplateFormPickerModal', {
            props: {
                type: props.type,
            },
        })
        .then(async (template) => {
            try {
                const response = await generateFormFromTemplate({
                    template_hash: template.hash,
                    formable_hash: props.formable.hash,
                    type: props.type,
                });

                window.open(
                    `/forms/${response.data.form.hash}/fill-out`,
                    '_blank',
                );
            } catch (e) {
                toast.error(trans('label.error'));
            }
        });
};
</script>

<template>
<MainContentLayout>
    <template #title>{{ $t("label.forms") }}</template>
    <template #actions>
        <StyledButton
            @click="selectNewForm"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{ $t("label.new_form") }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchForms"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type"
            :label="$t('label.type')"
            :placeholder="$t('label.type')"
            :options="templateTypes"
            name="type"
            @input="searchForms" />
        <StyledDatepicker
            v-model="params.dateFrom"
            :label="$t('label.date_from')"
            view-mode="month"
            name="date_from"
            @input="searchForms" />
        <StyledDatepicker
            v-model="params.dateTo"
            :disabled="!params.dateFrom"
            :label="$t('label.date_to')"
            view-mode="month"
            name="date_to"
            @input="searchForms" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            {
                title: $t('label.title'),
                key: 'title',
                value: (item) => `${item.title}`,
                sortable: false,
            },
            {
                title: $t('label.type'),
                key: 'type',
                value: (item) => `${item?.template?.type?.name ?? ''}`,
                sortable: false,
            },
            {
                title: $t('label.version'),
                key: 'version',
                value: (item) => `v${item.template.version_info.version}`,
                sortable: false,
            },
            {
                title: $t('label.date_added'),
                key: 'created_at',
                value: (item) => `${item.created_at.split('T')[0]}`,
                sortable: false,
            },
            {
                title: $t('label.created_by'),
                key: 'created_by',
                value: (item) =>
                    `${item.created_by?.staff?.first_name} ${item.created_by?.staff?.last_name}`,
                sortable: false,
            },
            {
                title: $t('label.last_edited_at'),
                key: 'updated_at',
                value: (item) => `${item.updated_at.split('T')[0]}`,
                sortable: false,
            },
            {
                title: $t('label.edited_by'),
                key: 'edited_by',
                value: (item) =>
                    `${item.edited_by?.staff?.first_name} ${item.edited_by?.staff?.last_name}`,
                sortable: false,
            },
        ]"
        :actions="[
            {
                title: trans('label.view'),
                icon: 'mdi-file-eye-outline',
                action: (item) => viewForm(item),
                isVisible: (item) => canView(item),
            },
            {
                title: trans('label.download'),
                icon: 'mdi-download',
                action: (item) => download(item),
                isVisible: (item) => canDownload(item),
            },
            {
                title: trans('label.delete'),
                icon: 'mdi-delete-forever-outline',
                action: (item) => destroyForm(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
