<script setup>
import { ref } from 'vue';
import { useProgramStore } from '@/stores/program';
import { storeToRefs } from 'pinia';

import TimelineViewer from '@/components/Timeline/TimelineViewer.vue';
import ProgramDetailsTab from '../../components/Programs/ProgramDetailsTab.vue';
import ProgramSafetyTab from '../../components/Programs/ProgramSafetyTab.vue';
import ProgramParticipationTab from '../../components/Programs/ProgramParticipationTab.vue';

const { active } = storeToRefs(useProgramStore());

const tab = ref('details');
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0,2]" />
    </template>
    <template #title>{{ $t('label.program_details', { name: active.name ?? '' }) }}</template>
    <template #actions />
    <div>
        <v-card>
            <v-tabs
                v-model="tab">
                <v-tab value="details">{{ $t('label.details') }}</v-tab>
                <v-tab value="safety">{{ $t('label.safety') }}</v-tab>
                <v-tab value="participation">{{ $t('label.participation') }}</v-tab>
                <v-tab value="timeline">{{ $t('label.timeline') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="details" class="p-4">
                        <ProgramDetailsTab :program="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="safety" class="p-4">
                        <ProgramSafetyTab :program="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="participation">
                        <ProgramParticipationTab :program="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="timeline">
                        <TimelineViewer :timelineable="active" type="program" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </div>
</MainContentLayout>
</template>
