<script setup>
import { computed, ref } from 'vue';
const emit = defineEmits(['on-update']);
import WYSIWYG from '@/components/WYSIWYG';

// ====================================================
//          State
// ====================================================

const props = defineProps({
    formItem: {
        type: Object,
        required: true
    }
});

// The item that this component is rendering
const item = ref(props.formItem);

// The internal state of this item's response
// NOTE: Some input types have multiple responses. For fields that only have one, we use the first index.
const responses = ref([]); 

// ====================================================
//          Helpers
// ====================================================

const componentIs = (what) => {
    return item.value.template_item.type.name == what;
};

const itemHasExistingResponses = () => {
    return item.value.responses.length > 0;
};

const isDisabled = () => {
    return !item.value.is_editable;
};

const getResponseOptions = () => {
    return item.value.template_item.item_options.map((opt) => {
        return {
            value: opt.title,
            form_item_id: item.value.id,
            template_item_option_id: opt.id,
            response_value: null
        };
    });
};

const optionRequiresAdditionalResponse = (formItemOptionId) => {
    const option = item.value.template_item.item_options.find((opt) => opt.id === formItemOptionId);
    return option.requires_response;
};

const getOptionTitle = (formItemOptionId) => {
    const option = item.value.template_item.item_options.find((opt) => opt.id === formItemOptionId);
    return option.title;
};

const getTableColumnTypeForOption = (formItemOptionId) => {
    const option = item.value.template_item.item_options.find((opt) => opt.id === formItemOptionId);
    return option?.table_column_type?.name;
};

const getTableColumnCount = computed(() => {
    return item.value.template_item.item_options.length;
});

const getColumnTitles = () => {
    return item.value.template_item.item_options.map((opt) => opt.title);
};

const getRowCount = computed(() => {
    return Math.max(...responses.value.map(response => response.table_row), 0) + 1;
});

const getRows = computed(() => {
    const rows = Array.from({ length: getRowCount.value }, () => []); 

    responses.value.forEach(response => {
        rows[response.table_row]?.push(response);
    });

    return rows;
});

const removeRow = (rowIndex) => {
    responses.value = responses.value.filter((response) => response.table_row !== rowIndex);
    responses.value.forEach((response) => {
        if (response.table_row > rowIndex) {
            response.table_row -= 1;
        }
    });

    updateItemResponse();
};

const addRow = () => {
    const newRow = item.value.template_item.item_options.map((opt) => {
        return {
            id: null,
            form_item_id: item.value.id,
            template_item_option_id: opt.id,
            value: '', 
            response_value: null,
            table_row: getRowCount.value
        };
    });

    responses.value = responses.value.concat(newRow);
    updateItemResponse();
};

const tableColumnTypeIs = (formItemOptionId, type) => {
    return getTableColumnTypeForOption(formItemOptionId) === type;
};

const getTableGridClass = computed(() => {
    return `grid grid-cols-${getTableColumnCount.value + 1}`;
});

const isChecklist = componentIs('Checklist');
const isTable = componentIs('Table');
const otherType = !isChecklist && !isTable;

// ====================================================
//          State Updating
// ====================================================

const updateItemResponse = () => {
    const updateItemResponseEmissionPayload = {
        form_item_id: item.value.id,
        responses: responses.value
    };

    emit('on-update', updateItemResponseEmissionPayload);
};

const updateChecklistItemResponse = (response, index) => {
    responses.value[index].value = response;
    updateItemResponse();
};

const handleWYSIWYGItemContentUpdateBlur = (content) => {
    responses.value[0].value = content;
    updateItemResponse();
};

// ====================================================
//          State Restoration
// ====================================================

// Special case for checklists
const restoreChecklistResponses = () => {
    responses.value = item.value.template_item.item_options.map((opt) => {
        const response = item.value.responses.find((res) => res.template_item_option_id === opt.id);

        return {
            id: response ? response.id : null,
            form_item_id: item.value.id,
            template_item_option_id: opt.id,
            // if exists, cast response to boolean, otherwise default false
            value: response ? response.value == '1' : false, 
            response_value: response ? response.response_value : null,
            table_row: response ? response.table_row : null 
        };
    });

    // Injects responses into the parent state
    updateItemResponse();
};

// Special case for tables
const restoreTableResponses = () => {
    // Tables are a special case:
    // - There are N rows - with a minimum of 1 row
    // - The options are the columns
    // - Some options require responses, others do not

    // If responses exist, restore them. Otherwise, generate 1 row of responses
    if (itemHasExistingResponses()) {
        const numberOfRows = item.value.responses.reduce((acc, response) => {
            return response.table_row > acc ? response.table_row : acc;
        }, 0) + 1;
        
        for (let row = 0; row < numberOfRows ; row++) {
            item.value.template_item.item_options.forEach((opt) => {
                const response = item.value.responses.find((res) => res.template_item_option_id === opt.id && res.table_row === row);
                let value = response ? response.value : null;

                if (response) {
                    const isCheckbox = tableColumnTypeIs(response.template_item_option_id, 'Yes/No');
                    if (isCheckbox && value) {
                        if (response.value == 1) {
                            value = true;
                        } else {
                            value = false;
                        }
                    }

                }
               
                responses.value.push({
                    id: response ? response.id : null,
                    form_item_id: item.value.id,
                    template_item_option_id: opt.id,
                    value: value, 
                    response_value: response ? response.response_value : null,
                    table_row: row
                });
            });
        }
    }

    if (!itemHasExistingResponses()) {
        responses.value = item.value.template_item.item_options.map((opt) => {
            return {
                id: null,
                form_item_id: item.value.id,
                template_item_option_id: opt.id,
                value: '', // the actual/primary response to this item
                // not to be confused with "value":
                // this is a special case for types that require an additional response.
                // For example, some checkboxes require a "response" (see requires_response on formItemOption) if the checkbox was marked true
                response_value: null,
                table_row: 0 // default row 
            };
        });
    }

};

const restoreOtherResponses = () => {

    const isSingleResponse = !item.value.has_options;

    if (itemHasExistingResponses()) {
        responses.value = item.value.responses.map((response) => {
            return {
                id: response.id,
                form_item_id: response.form_item_id,
                template_item_option_id: response.template_item_option_id,
                value: response.value,
                response_value: response.response_value,
                table_row: null 
            };
        });
    }

    // If no responses exist, generate a single response
    if (!itemHasExistingResponses() && isSingleResponse) {   
        let value = '';

        // Special situation for Text Editor
        // The "title" is the content of the editor
        if (componentIs('Text Editor')) {
            value = item.value.title;
        }
        
        responses.value = [{
            id: null,
            form_item_id: item.value.id,
            template_item_option_id: null,
            value: value, // the actual/primary response to this item
            // not to be confused with "value":
            // this is a special case for types that require an additional response.
            // For example, some checkboxes require a "response" (see requires_response on formItemOption) if the checkbox was marked true
            response_value: null,
            table_row: null 
        }];
    } 
};

// Checklists require a response for each option
if (isChecklist) {
    restoreChecklistResponses();
}

if (isTable) {
    restoreTableResponses();
}

if (otherType) {
    restoreOtherResponses();
}

</script>
<template>
<!-- eslint-disable vue/no-v-html -->
<div class="md:px-8 py-2">
    <div v-if="!componentIs('Text Editor')" class="text-lg pb-6 font-bold">
        {{  item.title }}
    </div>
    <div v-if="componentIs('Response Field')">
        <v-text-field
            v-model="responses[0].value"
            :placeholder="$t('label.response')"
            outlined
            dense
            @input="updateItemResponse" />
    </div>
    <div v-if="componentIs('Text Box')">
        <v-textarea
            v-model="responses[0].value"
            :placeholder="$t('label.response')"
            outlined
            dense
            @input="updateItemResponse" />
    </div>
    <div v-if="componentIs('Text Editor')">
        <WYSIWYG
            v-if="!isDisabled()"
            :disabled="isDisabled()"
            :initial-content="responses[0].value" 
            @contentUpdateBlur="handleWYSIWYGItemContentUpdateBlur($event, item)" />
        <div v-else v-html="responses[0].value" />
    </div>
    <div v-if="componentIs('Single Select')">
        <v-select
            v-model="responses[0]"
            :items="getResponseOptions()"
            :label="$t('label.select_one')"
            item-title="value"
            item-value="value"
            return-object
            dense
            outlined
            @update:modelValue="updateItemResponse" />
    </div>
    <div v-if="componentIs('Multi Select')">
        <v-select
            v-model="responses"
            :items="getResponseOptions()"
            :label="$t('label.select_multiple')"
            item-title="value"
            item-value="value"
            return-object
            dense
            outlined
            multiple
            @update:modelValue="updateItemResponse" />
    </div>
    <div v-if="componentIs('Checklist')">
        <div
            v-for="(response, index) in responses"
            :key="`${formItem.id}_checklist_option_${index}`"
            class="flex gap-4">
            <v-checkbox
                v-model="response.value"
                :label="getOptionTitle(response.template_item_option_id)"
                dense
                outlined
                @update:modelValue="updateChecklistItemResponse($event, index)" />
            <v-text-field
                v-if="optionRequiresAdditionalResponse(response.template_item_option_id) && response.value"
                v-model="response.response_value"
                :placeholder="$t('label.required_response')"
                outlined
                dense
                @input="updateItemResponse" />
        </div>
    </div>
    <div v-if="componentIs('Table')">
        <div class="gap-4 border-b mb-4 uppercase font-bold" :class="getTableGridClass">
            <div v-for="(colTitle, index) in getColumnTitles()" :key="`${formItem.id}_col_title_${colTitle}_${index}`">
                {{ colTitle }}
            </div>
            <div>{{ $t('label.remove') }}</div>
        </div>
        <div
            v-for="(row, rowIndex) in getRows"
            :key="`${formItem.id}_row_${rowIndex}`"
            class="gap-4 mb-4"
            :class="getTableGridClass">
            <div v-for="(cell, cellIndex) in row" :key="`${formItem.id}_cell_${cellIndex}`">
                <v-text-field
                    v-if="tableColumnTypeIs(cell.template_item_option_id, 'Open')"
                    v-model="cell.value"
                    outlined
                    dense
                    @input="updateItemResponse" />
                <v-text-field
                    v-if="tableColumnTypeIs(cell.template_item_option_id, 'Date Picker')"
                    v-model="cell.value"
                    type="date"
                    outlined
                    dense
                    @input="updateItemResponse" />
                <v-checkbox
                    v-if="tableColumnTypeIs(cell.template_item_option_id, 'Yes/No')"
                    v-model="cell.value"
                    dense
                    outlined
                    @update:modelValue="updateItemResponse" />
            </div>
            <div v-if="rowIndex != 0">
                <v-icon class="pt-4" color="red" @click="removeRow(rowIndex)">mdi-trash-can</v-icon>
            </div>
        </div>
        <v-btn @click="addRow">{{ $t('label.add_row') }}</v-btn>
    </div>
</div>
</template>
