<template>
<LoginContainer>
    <template #title>{{ $t('auth.forgot-password') }}</template>
    <form @submit.prevent="forgotPassword">
        <template v-if="!displayCheckEmailMessage">
            <StyledInput
                v-model="email"
                type="email"
                name="email"
                :placeholder="$t('label.email')"
                :label="$t('label.email')" />
            <StyledButton
                :full="true"
                type="submit"
                class="mb-4">
                {{ $t('actions.reset') }}
            </StyledButton>
        </template>
        <div
            v-else
            class="flex flex-col">
            <p>{{ $t('auth.sent_instructions_to', email) }}</p>
            <a class="router-link" href="/">{{ $t('auth.head_back_home') }}</a>
        </div>
    </form>
</LoginContainer>
</template>

<script>
import axios from 'axios';
import LoginContainer from '@/components/Auth/LoginContainer';

export default {
    components: {
        LoginContainer,
    },

    data: _ => ({
        errors: {},
        email: '',
        displayCheckEmailMessage: false
    }),

    computed: {
        getError() {
            return selector => {
                const error = this.errors[selector] && this.errors[selector].find(a => a);
                return error || '';
            };
        }
    },

    methods: {
        async forgotPassword() {
            try {
                this.errors = {};
                axios.get('/sanctum/csrf-cookie');
                await axios.post('/password/email', { email: this.email });
                this.displayCheckEmailMessage = true;
            } catch (e) {
                this.errors = e?.response?.data.errors;
            }
        }
    }
};
</script>
