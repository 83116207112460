import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useFormStore = defineStore('form', () => {
    const all = ref({ data: [] });

    const active = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const searchTimelineForms = (params) => {
        return axios.get(`/api/v1/forms/timeline/${params.hash}`, { params });
    };   

    const searchApplicationForms = (params) => {
        return axios.get(`/api/v1/forms/application/${params.hash}`, { params });
    };

    const searchIntakeTourForms = (params) => {
        return axios.get(`/api/v1/forms/intake-tour/${params.hash}`, { params });
    };

    const searchClientForms = (params) => {
        return axios.get(`/api/v1/forms/client/${params.hash}`, { params });
    };

    const searchSupportPlanForms = (params) => {
        return axios.get(`/api/v1/forms/support-plan/${params.hash}`, { params });
    };   

    const searchSupportPlanGoalEventForms = (params) => {
        return axios.get(`/api/v1/forms/support-plan-goal-event/${params.hash}`, { params });
    };   

    const generateFormFromTemplate = (params) => {
        return axios.post(`/api/v1/forms/templates/${params.template_hash}/generate`, params);
    };   

    const updateForm = (params) => {
        return axios.post(`/api/v1/forms/${params.hash}`, params);
    };

    const getFormOptions = () => {
        return axios.get('/api/v1/forms/options');
    };

    const getFormableTemplates = (params) => {  
        return axios.get('/api/v1/forms/templates/', { params });
    };

    const downloadForm = (params) => {
        return axios.get(`/api/v1/forms/${params.hash}/download`, { responseType: 'blob' });
    };

    const deleteForm = (params) => {
        return axios.delete(`/api/v1/forms/${params.hash}`);
    };

    return {
        active,
        reset,
        all,
        generateFormFromTemplate,
        updateForm,
        getFormOptions,
        getFormableTemplates,
        downloadForm,
        deleteForm,

        // searches
        searchTimelineForms,
        searchIntakeTourForms,
        searchApplicationForms,
        searchSupportPlanForms,
        searchSupportPlanGoalEventForms,
        searchClientForms,
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFormStore, import.meta.hot));
}
