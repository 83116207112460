<script setup>
import { ref, inject } from 'vue';
import { useRequirementStore } from '@/stores/requirement';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
const modals = inject('modals');

const { search } = useRequirementStore(); 
const { all } = storeToRefs(useRequirementStore());
const { requirementTypes } = storeToRefs(useOptionsStore());

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    per_page: 10,
    status: null,
    type: null
});

const searchRequirements = () => {
    search({
        ...params.value,
    });
};

const clearSearch = () => {
    params.value.term = '';
    searchRequirements();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchRequirements();
};

const openModal = (modal, mode = 'Create', requirement = null) => {
    modals.show(modal, {
        props: requirement ? {
            requirement: requirement,
            mode: mode
        } : {
            mode: mode,
        }
    }).then(() => {
        searchRequirements();
    });
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.requirement_management') }}</template>
    <template #actions>
        <StyledButton @click="openModal('RequirementsModal')">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_requirement') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchRequirements"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            clearable
            :placeholder="$t('label.status')"
            :options="[
                { label: $t('label.active'), value: true },
                { label: $t('label.inactive'), value: false },
            ]"
            name="status"
            @input="searchRequirements" />
        <StyledDropdown
            v-model="params.type"
            :placeholder="$t('label.type')"
            :options="requirementTypes"
            name="type"
            :label="$t('label.type')" 
            @input="searchRequirements" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.type'), key: 'type', value: item => `${item.type.name}`, sortable: false },
            { title: $t('label.title'), key: 'title', value: item => `${item.name}` },
            { title: $t('label.status'), key: 'status', value: item => `${item.is_active ? $t('label.active') : $t('label.inactive')}`, sortable: false },
            { title: $t('label.expiry_date'), key: 'expiry_date', value: item => `${item.expiry_date}`, sortable: false },
            { title: $t('label.required_for_staff'), key: 'required_for_staff', value: item => `${item.required_for_staff ? $t('label.yes') : $t('label.no')}`, sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.edit'),
                icon: 'mdi-pencil',
                action: (item) => openModal('RequirementsModal', 'Edit', item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
