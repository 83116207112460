<script setup>
import { ref } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { storeToRefs } from 'pinia';
import StaffAccountTab from '../../components/Staff/StaffAccountTab.vue';
import StaffDetailsTab from '../../components/Staff/StaffDetailsTab.vue';
import StaffEmploymentTab from '../../components/Staff/StaffEmploymentTab.vue';
import StaffRequirementsTab from '../../components/Staff/StaffRequirementsTab.vue';
import TimelineViewer from '../../components/Timeline/TimelineViewer.vue';

const { active } = storeToRefs(useStaffStore());

const tab = ref('account');
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0,2]" />
    </template>
    <template #title>{{ $t('label.staff_details', {
        firstName: active?.first_name ?? '', 
        lastName: active?.last_name ?? '', 
        role: active?.user?.roles[0]?.display_name ?? '',
    }
    ) }}</template>
    <template #actions />
    <div>
        <v-card>
            <v-tabs
                v-model="tab">
                <v-tab value="account">{{ $t('label.account') }}</v-tab>
                <v-tab value="details">{{ $t('label.details') }}</v-tab>
                <v-tab value="employment">{{ $t('label.employment') }}</v-tab>
                <v-tab value="training">{{ $t('label.training') }}</v-tab>
                <v-tab value="timeline">{{ $t('label.timeline') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="account" class="p-4">
                        <StaffAccountTab :staff="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="details" class="p-4">
                        <StaffDetailsTab :staff="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="employment">
                        <StaffEmploymentTab :staff="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="training">
                        <StaffRequirementsTab :staff="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="timeline">
                        <TimelineViewer :timelineable="active" type="staff" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </div>
</MainContentLayout>
</template>
