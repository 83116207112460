import { readonly, computed } from 'vue';
import { useLoaderStore } from '@/stores/loader';

const GlobalProvidePlugin = {
    install (app) {
        const loaderStore = useLoaderStore();
        const isLoading = computed(() => {
            return loaderStore.anyLoading;
        });

        // Provide the state and functions globally
        app.provide('isLoading', readonly(isLoading));
    }
};

export default GlobalProvidePlugin;
