<script setup >
import axios from 'axios';
import LoginContainer from '@/components/Auth/LoginContainer';
import PasswordRequirementsList from '@/components/Auth/PasswordRequirementsList.vue';
import { ref, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    errors: {
        type: Object,
        default: _ => ({})
    },
    set: {
        type: Boolean,
        default: false
    }
});

const user = ref({
    email: '',
    password: '',
    passwordConfirm: '',
    token: '',
});

function getError(selector) {
    const error = props.errors[selector];
    return error || '';
};

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    user.value.email = urlParams.get('email');
    user.value.token = urlParams.get('token');
});

async function reset() {
    try {
        await axios.get('/sanctum/csrf-cookie');
        await router.post('/password-reset', {
            token: user.value.token,
            email: user.value.email,
            password: user.value.password,
            password_confirmation: user.value.passwordConfirm,
            set: props.set,
        });
    } catch (e) {
        //
    }
};
</script>
<template>
<LoginContainer>
    <template #title>{{ set ? $t('auth.setup_password') : $t('label.reset_password') }}</template>
    <form @submit.prevent="reset()">
        <div class="grid grid-cols-1 space-y-2 my-5">
            <StyledInput
                v-model="user.email"
                name="email"
                type="email"
                disabled
                :placeholder="$t('label.email')"
                :label="$t('label.confirm_email')"
                :error-message="getError('email')" />
            <StyledPassword
                v-model="user.password"
                name="password"
                :placeholder="$t('label.password_placeholder')"
                :label="$t('label.password')"
                :error-message="getError('password')" />
            <StyledPassword
                v-model="user.passwordConfirm"
                name="password-confirm"
                :placeholder="$t('label.password_placeholder')"
                :label="$t('label.password_confirmation')"
                :error-message="getError('password')" />
            <PasswordRequirementsList :password="user.password" />
        </div>
        <StyledButton :full="true" type="submit">
            {{ set ? $t('label.save') : $t('label.reset') }}
        </StyledButton>
    </form>
</LoginContainer>
</template>
