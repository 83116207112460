<script setup>
import { ref, inject } from 'vue';
import { useProgramStore } from '@/stores/program';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';

const { search } = useProgramStore();
const { all } = storeToRefs(useProgramStore());
const { programTypes } = storeToRefs(useOptionsStore());

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    per_page: 10,
    type: null,
    status: null,
});

const searchPrograms = () => {
    search({
        ...params.value,
    });
};

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchPrograms();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchPrograms();
};

const accessProgram = (program) => {
    router.visit(`/programs/${program.hash}/details`);
};

const openModal = (modal, mode = 'Create', program = null) => {
    modals.show(modal, {
        props: program ? {
            program: program,
            mode: mode
        } : {
            mode: mode,
        }
    }).then(() => {
        searchPrograms();
    });
};

const getAddressString = (program) => {
    if (program.type.name != 'Off Site') {
        return 'n/a';
    }

    return  `${program.location_name} (${program.location_address} ${program.location_postal})`;
};

</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.program_management') }}</template>
    <template #actions>
        <StyledButton @click="openModal('ProgramsModal')"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_program') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchPrograms"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type"
            :placeholder="$t('label.type')"
            :options="programTypes"
            name="type"
            :label="$t('label.type')" 
            @input="searchPrograms" />
        <StyledDropdown
            v-model="params.status" 
            clearable
            :placeholder="$t('label.status')"
            :options="[
                { label: $t('label.active'), value: true },
                { label: $t('label.inactive'), value: false },
            ]"
            name="status"
            @input="searchPrograms" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'program_name', value: item => `${item.name}`, sortable: false },
            { title: $t('label.type'), key: 'program_type', value: item => `${item.type.name}`, sortable: false },
            { title: $t('label.schedule'), key: 'schedule', value: _ => `todo`, sortable: false },
            { title: $t('label.location'), key: 'location', value: item => getAddressString(item), sortable: false },
            { title: $t('label.client_number'), key: 'client_number', value:  item => `${item.client_number}`, sortable: false },
            { title: $t('label.duration'), key: 'duration', value: item => `${item.duration}`, sortable: false },
            { title: $t('label.status'), key: 'status', value: item => `${item.is_active ? $t('label.active') : $t('label.inactive')}`, sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessProgram(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
