<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useSupportPlanGoalEventStore } from '@/stores/supportPlanGoalEvent';
import { useOptionsStore } from '@/stores/options';
import { useDateFormatter } from '@/composables/dateFormatter';

const emit = defineEmits(['close-modal']);
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const { storeSupportPlanGoalEvent, updateSupportPlanGoalEvent } = useSupportPlanGoalEventStore();
const {
    timeOptions,
    programOptions
} = storeToRefs(useOptionsStore());

const props = defineProps({
    event: {
        type: Object,
        default: () => ({})
    },

    goal: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const form = ref({
    ...props.event,
    date: parseLocalDateFromString(props.event.date) ?? new Date()
});

const supportPlanDefinedOptions = computed(() => {
    return props.goal?.defined_options.map(option => ({
        label: option.defined_criteria_name,
        value: option.id
    }));
});

const save = async () => {
    const action = props.mode === 'Create' ? storeSupportPlanGoalEvent : updateSupportPlanGoalEvent;

    await action({
        support_plan_goal_id: props.goal.id,
        ...form.value,
        date: formatDate(form.value.date),
    });

    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Event'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'goal tracking event'}) }}</template>
    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <StyledInput
                :value="props.goal?.description"
                :disabled="true"
                :placeholder="$t('label.goal_description')"
                name="description" />
            <StyledDatepicker
                v-model="form.date"
                :placeholder="$t('label.date')"
                name="date"
                view-mode="month"
                :label="$t('label.date')" />
            <StyledDropdown
                v-model="form.time"
                :options="timeOptions"
                :label="$t('label.time')"
                name="time" />
            <StyledDropdown
                v-model="form.program_id"
                searchable
                :options="programOptions"
                :label="$t('label.programs')"
                name="programs" />
            <StyledInput
                v-if="props.goal.type.name == 'Measurable Criteria'"
                v-model="form.measurable_goal_criteria_value"
                :placeholder="$t('label.goal_unit')"
                name="measurable_goal_criteria_value"
                :label="$t('label.goal_unit')" />
            <StyledDropdown
                v-if="props.goal.type.name == 'Defined Criteria'"
                v-model="form.support_plan_defined_option_id"
                clearable
                :placeholder="$t('label.criteria_value')"
                :options="supportPlanDefinedOptions"
                name="support_plan_defined_option_id"
                :label="$t('label.criteria_value')" />
            <StyledInput
                v-model="form.description"
                :placeholder="$t('label.description')"
                name="description"
                :label="$t('label.description')" />
            <StyledDropdown
                v-model="form.was_breakthrough"
                :options="[{ label: $t('label.breakthrough'), value: true }, { label: $t('label.regression'), value: false }]"
                :label="$t('label.flag')"
                name="was_breakthrough" />
        </div>
    </form>
    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
