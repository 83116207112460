<script setup>
import { computed, ref, watch } from 'vue';

import { useToast } from 'vue-toastification';
import { trans } from 'laravel-vue-i18n';

import { storeToRefs } from 'pinia';

import { useFormStore } from '@/stores/form';
import FormComponentRenderer from '../../components/Forms/FormComponentRenderer.vue';

const { active } = storeToRefs(useFormStore());
const { updateForm } = useFormStore();

const toast = useToast();

const loading = ref(false);

const form = ref(active);
const formItemResponses = ref([]);

// Get responses for a specific template item (helper)
const getResponsesForFormItem = (form_item_id) => {
    const formItem = form.value.items.find((item) => item.id === form_item_id);

    if (formItem.responses) {
        return formItem.responses;
    } 

    return [];
};

// Initialize formItemResponses state
watch (active, (newActive) => {
    form.value = newActive;
    formItemResponses.value = [];

    form.value.items.forEach((item) => {
        formItemResponses.value.push({
            form_item_id: item.id,
            responses: getResponsesForFormItem(item.id)
        });
    });
}, { deep: true });

// Handle updating this components state from child components
const handleResponseUpdate = (itemResponseEmission) => {
    const form_item_id = itemResponseEmission.form_item_id;
    const form_item_responses = itemResponseEmission.responses;

    // Insert into formItemResponses if it doesn't exist, otherwise replace it
    const index = formItemResponses.value.findIndex((itemResponse) => itemResponse.form_item_id === form_item_id);
    if (index === -1) {
        formItemResponses.value.push({
            form_item_id: form_item_id,
            responses: form_item_responses
        });
    } else {
        formItemResponses.value[index] = {
            form_item_id: form_item_id,
            responses: form_item_responses
        };
    }
};

const handleFormSubmission = () => {
    loading.value = true;

    const payload = {
        hash: form.value.hash,
        formItemResponses: formItemResponses.value
    };

    updateForm(payload).then(_ => {
        toast.success(trans('label.saved'));
    }).catch(_ => {
        toast.error(trans('label.error'));
    }).finally(_ => {
        loading.value = false;
    });
};

import { usePage } from '@inertiajs/vue3';

const isPreviewMode = computed(() => {
    const queryParams = new URLSearchParams(usePage().url.split('?')[1] || '');
    return queryParams.get('is_preview') === 'true';
});
</script>

<template>
<div>
    <div v-if="isPreviewMode" class="text-center bg-primary-light text-white font-semibold py-8 text-lg">
        {{ $t('label.preview_mode') }}
    </div>
    
    <div v-show="loading" class="flex justify-center items-center h-screen gap-4">
        <v-progress-circular
            indeterminate
            color="primary"
            size="45" />
        <div>
            {{ $t('label.loading') }}
        </div>
    </div>
    <div v-show="!loading" class="m-12 border border-gray-light shadow-lg rounded-lg p-8 bg-white">
        <div class="text-2xl font-bold pb-8">{{ form.title }}</div>
        <div class="flex flex-col gap-4">
            <FormComponentRenderer
                v-for="(item, index) in form.items"
                :key="`form_item_${index}`"
                :form-item="item"
                @on-update="handleResponseUpdate" />
            <div v-if="!isPreviewMode" class="p-6 border-t border-gray-light">
                <StyledButton @click="handleFormSubmission">{{ $t('label.submit') }}</StyledButton>
            </div>
        </div>
    </div>
</div>
</template>
