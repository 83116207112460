<script setup>
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useProgramStore } from '@/stores/program';
import { useOptionsStore } from '@/stores/options';

const toast = useToast();
const { searchProgramTasks, updateProgramParticipants } = useProgramStore();
const { programTasks } = storeToRefs(useProgramStore());
const { clientOptions } = storeToRefs(useOptionsStore());

import { inject } from 'vue';
const modals = inject('modals');

const participants = ref([]);

const props = defineProps({
    program: {
        type: Object,
        default: () => null
    }
});

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
    status: null, 
    type: null, 
});

onMounted(() => {
    if (props.program) {
        participants.value = props.program.participants.map(client => client.id);
        searchProgramTasksFunc();
    }
});

const searchProgramTasksFunc = () => {
    searchProgramTasks({
        hash: props.program.hash,
        ...params.value,
    });
};

const openProgramTasksModal = (programTask = null) => {
    modals.show('ProgramTaskModal', {
        props: {
            mode: programTask ? 'Edit' : 'Create',
            task: programTask ? programTask : {},
            program: props.program,
        }
    }).then(() => {
        searchProgramTasksFunc();
    });
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchProgramTasksFunc();
};

const sendUpdateProgramParticipants = () => {
    updateProgramParticipants({
        hash: props.program.hash,
        participants: participants.value,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};

// Utility function to truncate text
const truncateText = (text, length = 30) => {
    if (text.length <= length) {
        return text;
    }
    return text.substring(0, length) + '...';
};

</script>

<template>
<div>

    <StyledAutoComplete
        v-model="participants"
        class="mt-5"
        name="participants"
        searchable
        multiple
        chips
        hint="Select participants"
        :options="clientOptions"
        :label="$t('label.add_participant')" 
        @input="sendUpdateProgramParticipants" />

    <div class="flex justify-end pb-4">
        <StyledButton @click="openProgramTasksModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_task') }}
        </StyledButton>
    </div>

    <DataTable
        v-if="programTasks && programTasks.data"
        :data="programTasks"
        :headers="[
            { title: $t('label.task_name'), key: 'name', value: item => `${item.name}` },
            { title: $t('label.outline'), key: 'outline', value: item => truncateText(item.outline) },
            { title: $t('label.outcome'), key: 'outcome_fufilment', value: item => truncateText(item.outcome_fufilment) },
            { title: $t('label.supplies_needed'), key: 'supply_requirement', value: item => truncateText(item.supply_requirement) },
        ]"
        :actions="[
            {
                title: $t('label.edit'),
                icon: 'mdi-pencil',
                action: (item) => openProgramTasksModal(item),
            },
        ]" 
        @table-update="tableUpdate" />
</div>
</template>
