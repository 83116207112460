<script setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { useOptionsStore } from '@/stores/options';

const { searchStaffRequirements } = useStaffStore();
const { staffRequirements } = storeToRefs(useStaffStore());

const { requirementTypes } = storeToRefs(useOptionsStore()); 

import { inject } from 'vue';
const modals = inject('modals');

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
    status: null, 
    type: null, 
});

const props = defineProps({
    staff: {
        type: Object,
        default: () => null
    }
});

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchStaffReqs();
};

const searchStaffReqs = () => {
    searchStaffRequirements({
        hash: props.staff.hash,
        ...params.value,
    });
};

const openStaffReqsModal = (staffRequirement = null) => {
    modals.show('StaffRequirementsModal', {
        props: {
            mode: staffRequirement ? 'Edit' : 'Create',
            staffRequirement: staffRequirement ? staffRequirement : {},
            staff: props.staff,
        }
    }).then(() => {
        searchStaffReqs();
    });
};

const clearSearch = () => {
    params.value.term = '';
    searchStaffReqs();
};

</script>

<template>
<div>
    <div class="flex justify-end pb-4">
        <StyledButton @click="openStaffReqsModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_training') }}
        </StyledButton>
    </div>

    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchStaffReqs"
            name="search"
            :label="$t('label.search_name')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type" 
            clearable
            :placeholder="$t('label.type')"
            :options="requirementTypes"
            name="type"
            @input="searchStaffReqs" />
        <StyledDropdown
            v-model="params.status" 
            clearable
            :placeholder="$t('label.status')"
            :options="[
                { label: $t('label.active'), value: true },
                { label: $t('label.inactive'), value: false },
            ]"
            name="status"
            @input="searchStaffReqs" />
    </div>
    <DataTable
        v-if="staffRequirements"
        :data="staffRequirements"
        :headers="[
            { title: $t('label.type'), key: 'type', value: item => `${item.type.name}`, sortable: false },
            { title: $t('label.title'), key: 'title', value: item => `${item.name}` },
            { title: $t('label.status'), key: 'status', value: item => `${item.is_active ? $t('label.active') : $t('label.inactive')}`, sortable: false },
            { title: $t('label.expiry_date'), key: 'expiry_date', value: item => `${item.expiry_date}`, sortable: false },
            { title: $t('label.issue_date'), key: 'issue_date', value: item => `${item.pivot.issue_date ? item.pivot.issue_date : $t('label.incomplete')}`, sortable: false },
            { title: $t('label.required_for_staff'), key: 'required_for_staff', value: item => `${item.required_for_staff ? $t('label.yes') : $t('label.no')}`, sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.edit'),
                icon: 'mdi-pencil',
                action: (item) => openStaffReqsModal(item),
            }
        ]"
        @table-update="tableUpdate" />
</div>
</template>
