<template>
<div class="bg-white px-4 pb-4 rounded-sm">
    <div class="justify-between items-center flex w-full pb-4">
        <div class="flex flex-col pt-4">
            <h1 class="h6 pl-1">
                <slot name="title" />
            </h1>
            <slot name="breadcrumb" />
        </div>

        <div class="">
            <slot name="actions" />
        </div>
    </div>
    <slot />
</div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>
