<script>
import { onMounted, onUnmounted, ref, computed } from 'vue';

export function useScreenSize() {
    const sm = 640;
    const md = 768;
    const lg = 1024;

    const vssWidth = ref(getScreenWidth());
    const vssHeight = ref(getScreenHeight());

    const smScreen = computed(() => vssWidth.value < sm);
    const mdScreen = computed(() => vssWidth.value < md);
    const lgScreen = computed(() => vssWidth.value < lg);

    function handleResize() {
        vssWidth.value = getScreenWidth();
        vssHeight.value = getScreenHeight();
    }

    function getScreenWidth() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }
    function getScreenHeight() {
        return (
            window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        );
    }
    onMounted(() => {
        window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
    });

    return {
        vssWidth,
        vssHeight,
        smScreen,
        mdScreen,
        lgScreen,
    };
}
</script>
