<script setup>
import { ref } from 'vue';
import { useSupportPlanStore } from '@/stores/supportPlan';
import { storeToRefs } from 'pinia';

import ClientSupportPlanDetailsTab from '../../components/Client/ClientSupportPlanDetailsTab.vue';
import ClientGoalTab from '../../components/Client/ClientGoalTab.vue';
import DocumentManager from '@/components/Documents/DocumentManager.vue';

const { active } = storeToRefs(useSupportPlanStore());
const tab = ref('goal_management');
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0,1]" />
    </template>
    <template #title>{{ $t('title.support_plan_management', { name: `${active.client?.first_name} ${active.client?.last_name}` ?? '', plan_type: `${active.type?.name}` ?? '' }) }}</template>
    <template #actions />
    <div> 
        <v-card>
            <v-tabs v-model="tab">
                <v-tab value="goal_management">{{ $t('label.goal_tracking') }}</v-tab>
                <v-tab value="support_plan_details">{{ $t('label.details') }}</v-tab>
                <v-tab value="documents">{{ $t('label.documents') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="goal_management" class="p-4">
                        <ClientGoalTab :support-plan="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="support_plan_details" class="p-4">
                        <ClientSupportPlanDetailsTab :support-plan="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="documents" class="p-4">
                        <DocumentManager :documentable="active" type="support-plan" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </div>
</MainContentLayout>
</template>
