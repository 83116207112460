<script setup>
import { ref, watch, onMounted } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const { relationships, provinces } = storeToRefs(useOptionsStore());

const props = defineProps({
    initialGuardians: {
        type: Array,
        default: () => []
    }
});

const guardians = ref([]);

onMounted(() => {
    guardians.value = props.initialGuardians;
});

const emit = defineEmits(['guardians-updated']);
watch(guardians, (newVal) => {
    emit('guardians-updated', newVal);
}, { deep: true });

const addGuardian = () => {
    guardians.value.push({
        first_name: '',
        last_name: '',
        relationship_id: null,
        province_id: null,
        email: '',
        phone: '',
        address: '',
        city: '',
        postal_code: '',
        client_resides_with: false,
    });
};

const removeGuardian = (index) => {
    guardians.value.splice(index, 1);
};

</script>

<template>
<div>
    <div v-for="(guardian, index) in guardians" :key="index" class="border-b border-gray p-4 mb-4">
        <div class="md:grid lg:grid-cols-2 xl:grid-cols-3 md:gap-4">
            <StyledInput 
                v-model="guardian.first_name"
                :label="$t('label.first_name')"
                :name="`guardians.${index}.first_name`" />
            <StyledInput 
                v-model="guardian.last_name"
                :label="$t('label.last_name')"
                :name="`guardians.${index}.last_name`" />
            <StyledDropdown 
                v-model="guardian.relationship_id"
                :options="relationships"
                :label="$t('label.relationship')"
                :name="`guardians.${index}.relationship_id`" />
            <StyledDropdown 
                v-model="guardian.province_id"
                :options="provinces"
                :label="$t('label.province')"
                :name="`guardians.${index}.province_id`"
                item-value="id"
                item-title="name" />
            <StyledInput 
                v-model="guardian.email"
                :label="$t('label.email')"
                :name="`guardians.${index}.email`" />
            <StyledInput 
                v-model="guardian.phone"
                :label="$t('label.phone')"
                :name="`guardians.${index}.phone`" />
            <StyledInput 
                v-model="guardian.address"
                :label="$t('label.address')"
                :name="`guardians.${index}.address`" />
            <StyledInput 
                v-model="guardian.city"
                :label="$t('label.city')"
                :name="`guardians.${index}.city`" />
            <StyledInput 
                v-model="guardian.postal_code"
                :label="$t('label.postal_code')"
                :name="`guardians.${index}.postal_code`" />
            <StyledCheckbox 
                v-model="guardian.client_resides_with"
                :label="$t('label.client_resides_with')"
                :name="`guardians.${index}.client_resides_with`" />
            <StyledButton 
                color="red" 
                size="small"    
                class="w-24 col-span-full"
                @click="removeGuardian(index)">
                {{ $t('label.remove') }}
            </StyledButton>
        </div>
    </div>
    <div class="bg-grey-200 my-4 pb-12 pt-12 px-12 border-black border border-dashed rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100" @click="addGuardian">
        <StyledButton class="flex items-center">
            <v-icon class="mr-2 text-white" icon="mdi-plus" />
            {{ $t('label.add_guardian') }}
        </StyledButton>
    </div>
</div>
</template>
