<script setup>
import { computed } from 'vue';
import { formatDistance } from 'date-fns';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
});

const duration = computed(() => {
    const startDate = new Date(props.startDate);
    const now = new Date();
    return formatDistance(startDate, now, { addSuffix: false });
});
</script>

<template>
<div>
    <v-badge
        color="primary"
        content="Years of Service">
        <template #badge>
            {{ $t('label.employed_for') }} {{ duration }}
        </template>
    </v-badge>
</div>
</template>
