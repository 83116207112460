<script setup>
import { ref, inject, onMounted } from 'vue';
import { useNoteStore } from '@/stores/note';
import { storeToRefs } from 'pinia';

const { all } = storeToRefs(useNoteStore());

const {
    searchTimelineNotes,
    searchSupportPlanGoalNotes,
    searchApplicationNotes,
    searchGoalEventNotes
} = useNoteStore();

const props = defineProps({
    noteable: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
});

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    dateFrom: null,
    dateTo: null,
    per_page: 10,
});

const searchNotes = () => {
    let action = null;

    switch (props.type) {
    case 'timeline':
        action = searchTimelineNotes;
        break;
    case 'goal':
        action = searchSupportPlanGoalNotes;
        break;
    case 'goalEvent':
        action = searchGoalEventNotes;
        break;
    case 'application':
        action = searchApplicationNotes;
        break;
    }

    if (!action) {
        console.error('No action found for note type');
    }

    action({
        ...params.value,
        hash: props.noteable.hash,
    });
};

onMounted(async () => {
    await searchNotes();
});

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchNotes();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, {
        page,
        sort_by: sortBy,
        per_page: itemsPerPage,
    });
    searchNotes();
};

// ====================================================
//        Form Actions
// ====================================================
const selectNewNote = async () => {
    await modals
        .show('NoteModal', {
            props: {
                noteableType: props.type,
                noteableId: props.noteable.id,
                mode: 'Create',
            },
        });
    searchNotes();
};
</script>

<template>
<MainContentLayout>
    <template #title>{{ $t("label.notes") }}</template>
    <template #actions>
        <StyledButton
            @click="selectNewNote"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{ $t("label.new_note") }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchNotes"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDatepicker
            v-model="params.dateFrom"
            :label="$t('label.date_from')"
            view-mode="month"
            name="date_from"
            @input="searchNotes" />
        <StyledDatepicker
            v-model="params.dateTo"
            :disabled="!params.dateFrom"
            :label="$t('label.date_to')"
            view-mode="month"
            name="date_to"
            @input="searchNotes" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            {
                title: $t('label.user'),
                key: 'title',
                value: (item) => `${item.user.staff.first_name} ${item.user.staff.last_name}`,
                sortable: false,
            },
            {
                title: $t('label.content'),
                key: 'type',
                value: (item) => item.content,
                sortable: false,
            },
            {
                title: $t('label.created_at'),
                key: 'created_by',
                value: (item) => item.created_at.split('T')[0],
                sortable: false,
            },
            {
                title: $t('label.last_edited_at'),
                key: 'updated_at',
                value: (item) => item.updated_at.split('T')[0],
                sortable: false,
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
