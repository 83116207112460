<script setup>
import { ref, watch, onMounted } from 'vue';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const { relationships } = storeToRefs(useOptionsStore());

const props = defineProps({
    initialAttendees: {
        type: Array,
        default: () => []
    }
});

const attendees = ref([]);

onMounted(() => {
    attendees.value = props.initialAttendees;
});

const emit = defineEmits(['attendees-updated']);
watch(attendees, (newVal) => {
    emit('attendees-updated', newVal);
}, { deep: true });

const addAttendee = () => {
    attendees.value.push({
        name: '',
        relationship_id: null,
    });
};

const removeAttendee = (index) => {
    attendees.value.splice(index, 1);
};
</script>

<template>
<div>
    <div v-for="(attendee, index) in attendees" :key="index" class="p-4">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <StyledInput 
                v-model="attendee.name"
                :label="$t('label.name')"
                :name="`attendees.${index}.name`" />
            <StyledDropdown 
                v-model="attendee.relationship_id"
                :options="relationships"
                :label="$t('label.relationship')"
                :name="`attendees.${index}.relationship_id`" />
            <v-icon
                class="mt-2 cursor-pointer"
                color="red"
                icon="mdi-trash-can-outline"
                @click="removeAttendee(index)" />
        </div>
    </div>
    <div class="flex gap-1 px-5 mb-8 border-b border-gray-light pb-8" @click="addAttendee">
        <v-icon icon="mdi-plus" />
        <span class="text-gray-500">{{ $t('label.add_attendee') }}</span>
    </div>
</div>
</template>
