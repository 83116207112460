<template>
<VueFinalModal
    v-bind="$attrs"
    classes="flex justify-center items-center"
    :content-class="[
        bodyClass,
        'modal-base',
        {'px-4 md:py-4': !noPadding}
    ]"
    :click-to-close="clickToClose"
    @opened="opened"
    @before-close="beforeClose">
    <slot />
</VueFinalModal>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { useErrorStore } from '@/stores/error';

defineOptions({
    inheritAttrs: true
});

const props = defineProps({
    bodyClass: {
        type: String,
        default: 'modal-large-content'
    },

    headerClass: {
        type: String,
        default: ''
    },

    // Keep errors on inputs when modal is closed
    keepErrors: {
        type: Boolean,
        default: false
    },

    // Gives modal a set height
    fixedHeight: {
        type: Boolean,
        default: true
    },

    noPadding: {
        type: Boolean,
        default: true
    },
    clickToClose: {
        type: Boolean,
        default: false
    }
});

onBeforeUnmount(() => clearErrors());

const reset = useErrorStore().reset;

function clearErrors() {
    if (!props.keepErrors) {
        reset();
    }
};

function opened() {
    // Add fixed position to html to prevent scrolling
    document.documentElement.classList.add('fixed');
}

function beforeClose() {
    // Remove fixed position from html
    document.documentElement.classList.remove('fixed');
}
</script>
