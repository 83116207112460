import { useLoaderStore } from '@/stores/loader';

const requestInterceptor = [
    request => {
        if (!request.precognitive) {
            useLoaderStore().add(request.url, request.method);
        }
        return request;
    },
    error => {
        useLoaderStore().remove(error.config.url, error.config.method);
        return Promise.reject(error);
    }
];

const responseInterceptor = [
    response => {
        useLoaderStore().remove(response.config.url, response.config.method);
        return response;
    },
    error => {
        useLoaderStore().remove(error.config.url, error.config.method);
        return Promise.reject(error);
    }
];

export default {
    request: requestInterceptor,
    response: responseInterceptor,
};
