<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useClientStore } from '@/stores/client';
import { useOptionsStore } from '@/stores/options';
import { useDateFormatter } from '@/composables/dateFormatter';

import GuardianSelector from '@/components/Client/GuardianSelector.vue';
import ContactSelector from '@/components/Client/ContactSelector.vue';

// Stores
const { updateClientProfile } = useClientStore();
const { genderOptions, provinceOptions } = useOptionsStore();

// Utils
const toast = useToast();
const { formatDate, formatDOB } = useDateFormatter();

const props = defineProps({
    client: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    first_name: '',
    last_name: '',
    preferred_name: '',
    gender_id: null,
    dob: '',
    phone: '',
    email: '',
    address: '',
    postal_code: '',
    province_id: null,
    sin: '',
    healthcare_number: '',
    medical_services_number: '',
    band_number: '',
    treaty_number: '',
    is_independent_adult: false,
});

const guardians = ref([]);
const contacts = ref([]);

watch(() => props.client, (client) => {
    if (client) {
        form.value = {
            first_name: client?.first_name ?? '',
            last_name: client?.last_name ?? '',
            preferred_name: client?.preferred_name ?? '',
            gender_id: client?.gender_id ?? null,
            dob: formatDOB(client?.dob),
            phone: client?.phone ?? '',
            email: client?.email ?? '',
            address: client?.address ?? '',
            postal_code: client?.postal_code ?? '',
            province_id: client?.province_id ?? null,
            sin: client?.sin ?? '',
            healthcare_number: client?.healthcare_number ?? '',
            medical_services_number: client?.medical_services_number ?? '',
            band_number: client?.band_number ?? '',
            treaty_number: client?.treaty_number ?? '',
            is_independent_adult: client?.is_independent_adult ?? false,
            is_active: client.is_active,
        };
        guardians.value = client?.guardians ?? [];
        contacts.value = client?.contacts ?? [];
    }
}, { immediate: true });

const updateGuardians = (updatedGuardians) => {
    guardians.value = updatedGuardians;
};

const updateContacts = (updatedContacts) => {
    contacts.value = updatedContacts;
};

const save = () => {
    const phone = form.value.phone?.replace(/-/g, '');

    updateClientProfile({
        hash: props.client.hash,
        ...form.value,
        dob: formatDate(form.value.dob),
        phone: phone,
        guardians: guardians.value,
        contacts: contacts.value,
    }).then(() => {
        toast.success(trans('label.saved'));
    }).catch(() => {
        toast.error(trans('label.error'));
    });
};
</script>

<template>
<div class="md:grid md:grid-cols-2 md:gap-4 lg:w-2/3 ">
    <div class="col-span-full text-lg mb-2 md:mb-4">{{ $t('label.client_profile') }}</div>
    <StyledInput
        v-model="form.first_name"
        :placeholder="$t('label.first_name')"
        name="first_name"
        type="text"
        :label="$t('label.first_name')" />

    <StyledInput
        v-model="form.last_name"
        :placeholder="$t('label.last_name')"
        name="last_name"
        type="text"
        :label="$t('label.last_name')" />

    <StyledInput
        v-model="form.preferred_name"
        :placeholder="$t('label.preferred_name')"
        name="preferred_name"
        type="text"
        :label="$t('label.preferred_name')" />

    <StyledDropdown
        v-model="form.gender_id"
        :placeholder="$t('label.gender')"
        :options="genderOptions"
        name="gender"
        :label="$t('label.gender')" />

    <StyledDatepicker
        v-model="form.dob"
        :placeholder="$t('label.dob')"
        name="dob"
        type="text"
        :label="$t('label.dob')" />

    <StyledMask
        v-model="form.phone"
        placeholder="###-###-####"
        :options="{ mask: '###-###-####' }"
        name="phone"
        :label="$t('label.phone')" />

    <StyledInput
        v-model="form.email"
        :placeholder="$t('label.email')"
        name="email"
        type="email"
        :label="$t('label.email')" />

    <StyledInput
        v-model="form.address"
        :placeholder="$t('label.address')"
        name="address"
        type="text"
        :label="$t('label.address')" />

    <StyledInput
        v-model="form.postal_code"
        :placeholder="$t('label.postal_code')"
        name="postal_code"
        type="text"
        :label="$t('label.postal_code')" />

    <StyledDropdown
        v-model="form.province_id"
        :placeholder="$t('label.province')"
        :options="provinceOptions"
        name="province"
        :label="$t('label.province')" />

    <StyledInput
        v-model="form.sin"
        :placeholder="$t('label.sin')"
        name="sin"
        type="text"
        :label="$t('label.sin')" />

    <StyledInput
        v-model="form.healthcare_number"
        :placeholder="$t('label.healthcare_number')"
        name="healthcare_number"
        type="text"
        :label="$t('label.healthcare_number')" />

    <StyledInput
        v-model="form.medical_services_number"
        :placeholder="$t('label.medical_services_number')"
        name="medical_services_number"
        type="text"
        :label="$t('label.medical_services_number')" />

    <StyledInput
        v-model="form.band_number"
        :placeholder="$t('label.band_number')"
        name="band_number"
        type="text"
        :label="$t('label.band_number')" />

    <StyledInput
        v-model="form.treaty_number"
        :placeholder="$t('label.treaty_number')"
        name="treaty_number"
        type="text"
        :label="$t('label.treaty_number')" />

    <StyledRoundSwitch
        v-model="form.is_active"
        color="primary"
        inset
        name="is_active"
        :label="$t('label.active')" />

    <StyledCheckbox
        v-model="form.is_independent_adult"
        :label="$t('label.is_independent_adult')"
        name="is_independent_adult" />

    <v-divider v-if="!form.is_independent_adult" class="col-span-full mb-4" />

    <div v-if="!form.is_independent_adult" class="col-span-full text-lg mb-4">
        {{ $t('label.client_guardians') }}
        <div class="col-span-full mt-2 md:mt-4">
            <GuardianSelector 
                :initial-guardians="client.guardians" 
                @guardians-updated="updateGuardians" />
        </div>
    </div>
    <v-divider class="col-span-full mb-4" />

    <div class="col-span-full text-lg mb-4">{{ $t('label.client_contacts') }}
        <div class="col-span-full mt-2 md:mt-4">
            <ContactSelector 
                :initial-contacts="client.contacts" 
                @contacts-updated="updateContacts" />
        </div>
    </div>
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>
</div>
</template>
