//This will import all default material vuetify colors and rename them to match tailwindcss
import colors from 'vuetify/lib/util/colors';

const tailwindColors = {
    transparent: {
        DEFAULT: 'transparent'
    },
    white: {
        DEFAULT: '#ffffff',
    },
    black: {
        DEFAULT: '#000000',
    },
    primary: {
        DEFAULT: '#1998FF',
        light: '#4CAFFF',
        dark: '#0056CC',
    },
    secondary: {
        DEFAULT: '#6096A3',
        light: '#A0BFCF',
        dark: '#405B72'
    },
    success: {
        DEFAULT: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20'
    },
    info: {
        DEFAULT: '#0288d1',
        light: '#03a9f4',
        dark: '#01579b'
    },
    accent: {
        DEFAULT: '#007EE5',
    },
    error: {
        DEFAULT: '#d32f2f',
        light: '#ef5350',
        dark: '#c62828'
    },
    warning: {
        DEFAULT: '#ed6c02',
        light: '#ff9800',
        dark: '#e65100'
    },
    gray: {
        DEFAULT: '#9e9e9e',
        light: '#bdbdbd',
        dark: '#616161'
    },
};

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

// Define your original object
let tailwindMaterialColorPack = colors;

// Define the new key mapping
const tailwindMap = {
    lighten5: '100',
    lighten4: '200',
    lighten3: '300',
    lighten2: '400',
    lighten1: '500',
    darken1: '600',
    darken2: '700',
    darken3: '800',
    darken4: '900',
    accent1: 'accent-100',
    accent2: 'accent-200',
    accent3: 'accent-300',
    accent4: 'accent-400',
    base: 'DEFAULT',
};

// Apply the function to each color object
Object.keys(tailwindMaterialColorPack).forEach(color => {
    tailwindMaterialColorPack[color] = renameKeys(tailwindMaterialColorPack[color], tailwindMap);
    tailwindMaterialColorPack[color].base = tailwindMaterialColorPack[color].DEFAULT;
});

// const tailwindColorFormatted = {};

// Object.keys(tailwindColors).forEach(color => {
//     tailwindColorFormatted[`${color}`] = tailwindColors[color]['DEFAULT'];
//     tailwindColorFormatted[`${color}-100`] = tailwindColors[color]['100'];
// });

// Merge the two objects
tailwindMaterialColorPack = { ...tailwindMaterialColorPack, ...tailwindColors };

export const colorConfig = tailwindMaterialColorPack;
