import './bootstrap';
// import './echo'; // Enable when we need WS support
import './css';
import './router';

const APP_NAME = import.meta.env.VITE_APP_NAME;
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { vuetify } from '@/plugins/vuetify/vuetify';

//Plugins
import { i18nVue } from 'laravel-vue-i18n';
import StyledInputs from '@pderas/styled-inputs';
import GlobalProvidePlugin from '@/plugins/globalProviderPlugin';
import { vue3Debounce } from 'vue-debounce';

// Pinia
import { useErrorStore } from '@/stores/error';
import { hydratePinia } from './utils/hydratePinia';
import { createPinia } from 'pinia';
const pinia = createPinia();

//FA
import * as fontAwesomeConfig from './fontAwesomeConfig';
const { library, dom, FontAwesomeIcon, ...faIcons } = fontAwesomeConfig;
library.add(faIcons);
dom.watch();

//Components
import DataTable from '@/components/DataTable';
import BreadCrumb from '@/components/BreadCrumb';
import AuthLayout from '@/layouts/AuthLayout';
import MainLayout from '@/layouts/MainLayout';
import MainContentLayout from '@/layouts/MainContentLayout';
import FormLayout from '@/layouts/FormLayout.vue';
import { vTooltip } from 'floating-vue';
import { vfmPlugin } from 'vue-final-modal';
import StyledSaveButton from '@/components/StyledSaveButton';

//WYSIWYG
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

//Modals
import Modals from '@/modals';
const ImportModals = import.meta.glob('./modals/modals/*.vue', { eager: true });

//Toast
import Toast from 'vue-toastification';
import Toasted from '@/plugins/toast';
import { TYPE } from 'vue-toastification';
const toastOptions = {
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            timeout: 10000,
            closeButton: false,
            position: 'bottom-right'
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            hideProgressBar: true,
            position: 'bottom-right'
        }
    }
};

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./pages/**/*', { eager: true });
        let page = pages[`./pages/${name}.vue`];

        if (name.includes('Auth/')) {
            page.default.layout = AuthLayout;
        } else if (name == 'Forms/FillOutFormPage') {
            page.default.layout = FormLayout;
        } else {
            page.default.layout = MainLayout;
        }
         
        return page;
    },
    title: title => `${title ? title + ' - ' + APP_NAME : APP_NAME}`,
    setup({ el, App, props, plugin }) {
        const app = createApp({
            mounted() {
                // This runs on page refresh
                hydratePinia(this.$inertia.page.props);

                // This runs on normal inertia page changes
                this.$inertia.on('finish', _ => {
                    hydratePinia(this.$inertia.page.props);
                });
            },
            render: () => h(App, props)
        });

        //Directives
        app.directive('debounce', vue3Debounce({ lock: true }));
        app.directive('tooltip', vTooltip);

        //Plugins
        const modalGlobal = new Modals();
        app.config.globalProperties.$modals = modalGlobal;
        app.provide('modals', modalGlobal);
        app.use(pinia);
        app.use(StyledInputs, {
            errorStore: useErrorStore(),
        });
        app.use(Toast, toastOptions);
        app.use(Toasted);
        app.use(GlobalProvidePlugin);
        app.use(plugin);
        app.use(vfmPlugin);
        app.use(vuetify);
        app.use(i18nVue, {
            fallbackMissingTranslations: true,
            resolve: async lang => {
                const langs = import.meta.glob('../../lang/*.json');
                if (lang.includes('php_')) {
                    return await langs[`../../lang/${lang}.json`]();
                }
            }
        });
        app.use(CkeditorPlugin);

        //Components
        app.component('MainContentLayout', MainContentLayout);
        app.component('FormLayout', FormLayout);
        app.component('FontAwesomeIcon', FontAwesomeIcon);
        app.component('DataTable', DataTable);
        app.component('BreadCrumb', BreadCrumb);
        app.component('StyledSaveButton', StyledSaveButton);
        Object.entries(ImportModals).forEach(([path, definition]) => {
            //Imports all modals with the same name UsersModal.vue => UsersModal
            app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
        });

        //Mount
        app.mount(el);
    },
});
