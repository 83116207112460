import NProgress from 'nprogress';

/**
 * NProgress Configuration
 * http://ricostacruz.com/nprogress/
 * https://github.com/rstacruz/nprogress/
 */
NProgress.configure({
    showSpinner: false,
    easing: 'ease',
    speed: 500
});

export { NProgress };
