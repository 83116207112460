<script setup>
import { ref, inject } from 'vue';
import { Head } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import StyledCluster from '@/components/LayoutCompositions/StyledCluster.vue';

const params = ref({
    name: '',
    name2: '',
    password: '',
    dropdown: null,
    dropdown2: null,
    dropdown3: null,
    dropdown4: null,
    dropdown5: null,
    checkbox: false,
    is_active: false,
    textarea: '',
    currency: 0,
    datepicker: null,
    datepicker2: null,
    search: '',
    radio: null,
    file: null,
    file2: null,
    mask: '',
    mask2: '',
    effective_date: null
});

const toast = useToast();
const modals = inject('modals');

const save = () => {
    toast.success('Saved!');
};

const openModal = () => {
    modals.show('UsersModal');
};
</script>

<template>
<MainContentLayout>
    <Head title="Dashboard" />

    <template #breadcrumb> <BreadCrumb /></template>
    <template #title>
        {{ $t('label.dashboard') }}
    </template>

    <form class="md:grid md:grid-cols-2 gap-4" @submit.prevent="save">
        <StyledInput
            v-model="params.name"
            name="name"
            density="compact"
            counter="30"
            :label="$t('label.compact_density')" />
        <StyledInput
            v-model="params.name2"
            color="primary"
            name="name2"
            counter="30"
            :label="$t('label.text_input_with_counter')" />
        <StyledPassword
            v-model="params.password"
            name="password"
            :label="$t('label.simple_password')" />
        <StyledPassword
            v-model="params.password"
            name="password"
            icon-show="mdi-eye-outline"
            icon-hide="mdi-emoticon"
            :label="$t('label.different_icons')" />
        <StyledCheckbox
            v-model="params.checkbox"
            name="name"
            :label="$t('label.check_box')" />
        <StyledDropdown
            v-model="params.dropdown"
            name="dropdown"
            :options="['test', 'test2']"
            :label="$t('label.simple_dropdown')" />

        <StyledDropdown
            v-model="params.dropdown2"
            name="dropdown"
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            :label="$t('label.object_dropdown')" />
        <StyledDropdown
            v-model="params.dropdown3"
            name="dropdown"
            multiple
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            :label="$t('label.multiple_dropdown')" />
        <StyledDropdown
            v-model="params.dropdown4"
            name="dropdown"
            multiple
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            :label="$t('label.multiple_dropdown')" />
        <StyledDropdown
            v-model="params.dropdown5"
            name="dropdown"
            multiple
            chips
            item-title="title"
            item-value="id"
            return-object
            hint="What are the target regions"
            persistent-hint
            :options="[{id: 'test', title: 'Test' }, {id: 'test2', title: 'Test 2' }]"
            :label="$t('label.multiple_dropdown_helper_text')" />
        <StyledRoundSwitch
            v-model="params.is_active"
            :label="$t('label.is_active')"
            color="primary"
            inset
            name="is_active" />
        <StyledSquareSwitch
            v-model="params.is_active"
            :label="$t('label.square_switch')"
            color="secondary"
            name="is_active" />
        <StyledDatepicker
            v-model="params.datepicker"
            :label="$t('label.date_picker')"
            min="2024-01-01"
            max="2024-01-15"
            color="secondary"
            date-picker-color="secondary"
            name="datepicker" />
        <StyledDatepicker
            v-model="params.datepicker2"
            :label="$t('label.date_picker_range')"
            view-mode="year"
            :range="true"
            name="datepicker2" />
        <StyledTextArea
            v-model="params.textarea"
            :label="$t('label.text_area')"
            class="col-span-2"
            name="textarea" />
        <StyledSearch
            v-model="params.search"
            name="search"
            :label="$t('label.styled_search')" />
        <StyledSearch
            v-model="params.search"
            v-debounce:500ms="save"
            :debounce-events="['input']"
            name="search"
            :label="$t('label.styled_search_debounce')" />
        <StyledRadio
            v-model="params.radio"
            name="name"
            color="primary"
            radio-color="primary"
            item-value="cValue"
            item-title="cLabel"
            inline
            :options="[{cValue: 'test', cLabel: 'Test' }, {cValue: 'test2', cLabel: 'Test 2' }]"
            :label="$t('label.radio_inline')" />
        <StyledRadio
            v-model="params.radio"
            name="radio"
            :options="[{value: 'test', label: 'Test' }, {value: 'test2', label: 'Test 2' }]"
            :label="$t('label.radio')" />
        <StyledFileSelector
            v-model="params.file"
            name="file"
            :label="$t('label.file_selector')" />
        <StyledFileSelector
            v-model="params.file2"
            name="file"
            multiple
            counter
            :label="$t('label.fancy_file')">
            <template #selection="{ fileNames }">
                <template v-for="fileName in fileNames" :key="fileName">
                    <v-chip
                        size="small"
                        label
                        color="primary"
                        class="me-2">
                        {{ fileName }}
                    </v-chip>
                </template>
            </template>
        </StyledFileSelector>

        <StyledMask
            v-model="params.mask"
            name="mask"
            placeholder="###-###-####"
            :options="{ mask: '###-###-####' }"
            :label="$t('label.phone_number_masked_input')" />
        <StyledMask
            v-model="params.mask2"
            name="mask2"
            :placeholder="$t('label.postal_code_input')"
            :options="{ mask: '@#@-#@#' }"
            :label="$t('label.postal_code_masked_input')" />

        <StyledCurrency
            v-model="params.currency"
            color="primary"
            :placeholder="$t('label.nil_amt')"
            name="currency"
            :label="$t('label.currency')" />

        <div class="flex gap-4 items-center">
            <div class="flex gap-4 items-center">
                {{ $t('label.font_awesome_icon') }} <FontAwesomeIcon icon="pencil" />
            </div>
            <div>{{ $t('label.v_s') }}</div>
            <div class="flex gap-4 items-center">
                <v-icon icon="mdi-pencil" /> {{ $t('label.material_design_icon') }}
            </div>
        </div>
        <StyledButton
            v-tooltip="'Tooltip example.'"
            type="submit">
            {{ $t('label.full_width_button') }}
        </StyledButton>
        <div>
            <StyledButton
                @click="openModal">{{ $t('label.open_modal_example') }}</StyledButton>
        </div>
    </form>

    <div class="flex flex-col sm:grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-12">
        <StyledButton color="primary">{{ $t('label.button_primary') }}</StyledButton>
        <StyledButton color="secondary">{{ $t('label.button_secondary') }}</StyledButton>
        <StyledButton color="success">{{ $t('label.button_success') }}</StyledButton>
        <StyledButton color="info">{{ $t('label.button_info') }}</StyledButton>
        <StyledButton color="warning">{{ $t('label.button_warning') }}</StyledButton>
        <StyledButton color="error">{{ $t('label.button_error') }}</StyledButton>
        <StyledButton color="accent">{{ $t('label.button_accent') }}</StyledButton>
    </div>
    <StyledCluster narrow>
        <StyledButton size="large" class="w-48" color="primary">{{ $t('label.button_primary') }}</StyledButton>
        <StyledButton size="large" color="secondary">{{ $t('label.button_secondary') }}</StyledButton>
        <StyledButton size="large" color="success">{{ $t('label.button_success') }}</StyledButton>
        <StyledButton size="large" color="info">{{ $t('label.button_info') }}</StyledButton>
        <StyledButton size="large" color="warning">{{ $t('label.button_warning') }}</StyledButton>
        <StyledButton size="large" color="error">{{ $t('label.button_error') }}</StyledButton>
        <StyledButton size="large" color="accent">{{ $t('label.button_accent') }}</StyledButton>
    </StyledCluster>
    <div class="flex flex-col sm:grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-12">
        <StyledButton size="large" class="w-48" color="primary">{{ $t('label.button_primary') }}</StyledButton>
        <StyledButton size="large" color="secondary">{{ $t('label.button_secondary') }}</StyledButton>
        <StyledButton size="large" color="success">{{ $t('label.button_success') }}</StyledButton>
        <StyledButton size="large" color="info">{{ $t('label.button_info') }}</StyledButton>
        <StyledButton size="large" color="warning">{{ $t('label.button_warning') }}</StyledButton>
        <StyledButton size="large" color="error">{{ $t('label.button_error') }}</StyledButton>
        <StyledButton size="large" color="accent">{{ $t('label.button_accent') }}</StyledButton>
    </div>

    <div class="font-weight-bold font-bold">
        <h1>{{ $t('label.typography') }}</h1>
        <h1>{{ $t('label.first_heading') }}</h1>
        <h2>{{ $t('label.second_heading') }}</h2>
        <h3>{{ $t('label.third_heading') }}</h3>
        <h4>{{ $t('label.fourth_heading') }}</h4>
        <h5>{{ $t('label.fifth_heading') }}</h5>
        <h6>{{ $t('label.sixth_heading') }}</h6>
        <p>{{ $t('label.paragraph') }}</p>
    </div>

</MainContentLayout>
</template>
