<script>

export function useStringFormatter() {
    const capitalizeFirstLetter = (string = '') => string.length > 0 ? string.charAt(0).toUpperCase() + string.slice(1) : string;

    return {
        capitalizeFirstLetter,
    };
}

</script>
