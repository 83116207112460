import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useNoteStore = defineStore('note', () => {
    const all = ref({ data: [] });

    const reset = () => {
        all.value = { data: [] };
    };

    const searchTimelineNotes = (params) => {
        return axios.get(`/api/v1/notes/timeline/${params.hash}`, { params });
    };

    const searchApplicationNotes = (params) => {
        return axios.get(`/api/v1/notes/application/${params.hash}`, { params });
    };

    const searchSupportPlanGoalNotes = (params) => {
        return axios.get(`/api/v1/notes/support-plan-goal/${params.hash}`, { params });
    };

    const searchGoalEventNotes = (params) => {
        return axios.get(`/api/v1/notes/goal-event/${params.hash}`, { params });
    };

    const store = (params) => {
        return axios.post('/api/v1/notes/', params);
    };

    return {
        all,
        reset,
        searchTimelineNotes,
        searchApplicationNotes,
        searchSupportPlanGoalNotes,
        searchGoalEventNotes,
        store
    };
});
