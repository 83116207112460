<template>
<div class="flex flex-col max-h-[75vh]">
    <header
        v-if="$slots.header"
        class="px-4 py-4 md:pb-4 md:pt-10 md:px-10 rounded-2xl"
        :class="headerClass">
        <div class="flex justify-between">
            <h4 class="text-2xl text-black">
                <slot name="header" />
            </h4>
            <v-hover>
                <template #default="{ isHovering, props }">
                    <v-icon
                        v-bind="props"
                        icon="mdi-close"
                        size="36"
                        :color="isHovering ? 'primary' : undefined"
                        class="cursor-pointer"
                        @click="$emit('cancel-modal')" />
                </template>
            </v-hover>
        </div>
        <p
            v-if="$slots.subheader"
            class="text-black">
            <slot name="subheader" />
        </p>
    </header>

    <section
        class="modal-content"
        :class="{
            'modal-content-fixed-h': fixedHeight,
            'px-4 md:px-10 md:py-4': !noPadding
        }">
        <slot />
    </section>

    <footer v-if="$slots.footer" class="modal-footer py-4 md:pb-10 px-4 md:px-10">
        <slot name="footer" />
    </footer>
</div>
</template>

<script>
export default {
    inheritAttrs: true,

    props: {
        bodyClass: {
            type: String,
            default: 'modal-large-content'
        },

        headerClass: {
            type: String,
            default: ''
        },

        // Keep errors on inputs when modal is closed
        keepErrors: {
            type: Boolean,
            default: false
        },

        // Gives modal a set height
        fixedHeight: {
            type: Boolean,
            default: true
        },

        noPadding: {
            type: Boolean,
            default: false
        }
    },

    emits: ['cancel-modal'],
};
</script>
