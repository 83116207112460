<script setup>
import { computed } from 'vue';
import PasswordRequirement from '@/components/Auth/PasswordRequirement.vue';

const props = defineProps({
    password: {
        type: String,
        required: true,
    },
});

const minLength = 12;

const isLengthValid = computed(() => props.password.length >= minLength);
const isUppercaseValid = computed(() => /[A-Z]/.test(props.password));
const isLowercaseValid = computed(() => /[a-z]/.test(props.password));
const isNumberValid = computed(() => /\d/.test(props.password));
const isSpecialValid = computed(() => /[!@#$%^&*(),.?":{}|<>]/.test(props.password));

</script>
<template>
<div class="space-y-1 mx-2">
    <PasswordRequirement :valid="isLengthValid" :text="`Minimum ${minLength} characters`" />
    <PasswordRequirement :valid="isUppercaseValid" text="At least one uppercase letter" />
    <PasswordRequirement :valid="isLowercaseValid" text="At least one lowercase letter" />
    <PasswordRequirement :valid="isSpecialValid" text="At least one special character" />
    <PasswordRequirement :valid="isNumberValid" text="At least one number" />
</div>
</template>
