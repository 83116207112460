import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useSupportPlanGoalEventStore = defineStore('supportPlanGoalEvent', () => {
    const all = ref({ data: [] });
    const supportPlanGoalEvents = ref({});
    const active = ref({});

    const reset = () => {
        all.value = { data: [] };
    };

    const searchSupportPlanGoalEvents = async (params) => {
        return axios.get(`/api/v1/supportplan/goal/${params.hash}/events`, { params });
    };

    const storeSupportPlanGoalEvent = (params) => {
        return axios.post('/api/v1/supportplan/goal/event/', params);
    };

    const updateSupportPlanGoalEvent = (params) => {
        return axios.patch(`/api/v1/supportplan/goal/event/${params.hash}`, params);
    };

    const destroy = (params) => {
        return axios.delete('/api/v1/goal/events/' + params.hash);
    };

    return {
        all,
        supportPlanGoalEvents,
        active,
        reset,
        searchSupportPlanGoalEvents,
        storeSupportPlanGoalEvent,
        updateSupportPlanGoalEvent,
        destroy,
    };
}
);
