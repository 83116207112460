<script setup>
import { ref, inject, onMounted } from 'vue';
import { useTimelineStore } from '@/stores/timeline';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';

import TimelineLink from '@/components/Timeline/TimelineLink.vue';

const { all } = storeToRefs(useTimelineStore());
const { timelineTypes } = storeToRefs(useOptionsStore());

const {
    searchClientTimeline,
    searchProgramTimeline,
    searchStaffTimeline,
    getTimelineOptions
} = useTimelineStore();

const props = defineProps({
    type: {
        type: String,
        required: true
    },
    // Staff/Client/Program object
    timelineable: {
        type: Object,
        default: () => null
    }
});

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    type: null,
    dateFrom: null,
    dateTo: null,
    per_page: 10,
});

const searchTimelines = () => {
    let action = null;

    switch (props.type) {
    case 'client':
        action = searchClientTimeline;
        break;
    case 'program':
        action = searchProgramTimeline;
        break;
    case 'staff':
        action = searchStaffTimeline;
        break;
    }
    
    action({
        hash: props.timelineable.hash,
        ...params.value,
    });
};

// When we load the component, we want to search for the timelines
onMounted(async () => {
    await getTimelineOptions();
    await searchTimelines();
});

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchTimelines();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchTimelines();
};

const openModal = (modal, mode = 'Create', timeline = null) => {
    modals.show(modal, {
        props: timeline ? {
            timeline: timeline,
            mode: mode
        } : {
            mode: mode,
            timelineableId: props.timelineable.id,
            timelineableType: props.type
        }
    }).then(() => {
        searchTimelines();
    });
};

const accessTimelineEvent = (timeline) => {
    router.visit(`/timelines/${timeline.hash}/`);
};

</script>

<template>
<MainContentLayout>
    <template #actions>
        <StyledButton @click="openModal('TimelinesModal')"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_event') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-4 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchTimelines"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type"
            clearable
            :placeholder="$t('label.type')"
            :options="timelineTypes"
            name="type"
            @input="searchTimelines" />
        <StyledDatepicker
            v-model="params.dateFrom"
            :label="$t('label.date_from')"
            view-mode="month"
            name="date_from"
            @input="searchTimelines" />
        <StyledDatepicker
            v-model="params.dateTo"
            :disabled="!params.dateFrom"
            :label="$t('label.date_to')"
            view-mode="month"
            name="date_to"
            @input="searchTimelines" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.type'), key: 'type', value: item => `${item.type.name}`, sortable: false },
            { title: $t('label.date_added'), key: 'created_at', value: item => `${item.created_at.split('T')[0]}`, sortable: false },
            { title: $t('label.created_by'), key: 'created_by', value: item => `${item.created_by?.staff?.first_name} ${item.created_by?.staff?.last_name}`, sortable: false },
            { title: $t('label.event_date_time'), key: 'event_date_time', value: item => `${item.date} ${item.time}`, sortable: false },
            { title: $t('label.event_links'), key: 'event_links', sortable: false }, 
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessTimelineEvent(item),
            },
        ]"
        @table-update="tableUpdate">
        <template #[`item.event_links`]="{ item }">
            <div class="flex flex-col gap-1">
                <TimelineLink
                    v-for="(program, idx) in item.programs"
                    :key="`program_${idx}_link`"
                    :timelineable="program"
                    type="program" />
                <TimelineLink
                    v-for="(client, idx) in item.clients"
                    :key="`client_${idx}_link`"
                    :timelineable="client"
                    type="client" />
                <TimelineLink
                    v-for="(staff, idx) in item.staff"
                    :key="`staff_${idx}_link`"
                    :timelineable="staff"
                    type="staff" />
            </div>
        </template>
    </DataTable>
</MainContentLayout>
</template>
