<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import NoteManager from '@/components/Notes/NoteManager.vue';

const props = defineProps({
    goal: {
        type: Object,
        default: null
    },
});

</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.note') }}</template>
    <template #subheader>{{ $t('label.create_note') }}</template>

    <NoteManager
        type="goal"
        :noteable="props.goal" />
</ModalWrapper>
</template>
