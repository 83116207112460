<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useStaffStore } from '@/stores/staff';
import { useDateFormatter } from '@/composables/dateFormatter';
import YearsOfService from './YearsOfService.vue';
// Stores
const { updateStaffEmployment } = useStaffStore();
// Utils
const toast = useToast();
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    staff: {
        type: Object,
        default: () => null
    }
});

const form = ref({
    //
});

// Restore staff related data
watch(() => props.staff, (staff) => {
    form.value = {
        is_employed: staff?.is_employed,

        employment_hire_date: staff?.employment_hire_date ? parseLocalDateFromString(staff?.employment_hire_date) : null,
        employment_end_date: staff?.employment_end_date ? parseLocalDateFromString(staff?.employment_end_date) : null,

        employment_reason_for_departure: staff?.employment_reason_for_departure,
        employment_hourly_rate: staff?.employment_hourly_rate,
        employment_vacation_weeks_per_year: staff?.employment_vacation_weeks_per_year,
        employment_rrsp_percent: staff?.employment_rrsp_percent,
        staff_fund_contribution: staff?.staff_fund_contribution,
    };  
}, { immediate: true });

const save = () => {
    updateStaffEmployment({
        hash: props.staff.hash,
        is_employed: form.value.is_employed,
        employment_hire_date: form.value.employment_hire_date ? formatDate(form.value.employment_hire_date) : null,
        employment_end_date: form.value.employment_end_date ? formatDate(form.value.employment_end_date) : null,
        employment_reason_for_departure: form.value.employment_reason_for_departure,
        employment_hourly_rate: form.value.employment_hourly_rate,
        employment_vacation_weeks_per_year: form.value.employment_vacation_weeks_per_year,
        employment_rrsp_percent: form.value.employment_rrsp_percent,
        staff_fund_contribution: form.value.staff_fund_contribution,
    }).then(_ => {
        toast.success(trans('label.saved'));
    }).catch(_ => {
        toast.error(trans('label.error'));
    });
};

</script>
<template>
<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />
    <div class="text-lg mb-4">{{ $t('label.employment_details') }}</div>
    <YearsOfService v-if="form.is_employed" :start-date="form.employment_hire_date" />

    <StyledRoundSwitch
        v-model="form.is_employed"
        class="col-span-full"
        color="primary"
        inset
        name="is_employed"
        :label="$t('label.employed')" />
    <StyledDatepicker   
        v-model="form.employment_hire_date"
        :placeholder="$t('label.hired_date')"
        name="employment_hire_date"
        :label="$t('label.hired_date')" />
    <StyledDatepicker
        v-if="!form.is_employed"
        v-model="form.employment_end_date"
        :placeholder="$t('label.end_date')"
        name="employment_end_date"
        :label="$t('label.end_date')" />
    <StyledInput
        v-if="!form.is_employed"
        v-model="form.employment_reason_for_departure"
        :placeholder="$t('label.employment_reason_for_departure')"
        name="employment_reason_for_departure"
        type="text"
        :label="$t('label.employment_reason_for_departure')" />
    <StyledCurrency
        v-model="form.employment_hourly_rate"
        :placeholder="$t('label.employment_hourly_rate')"
        name="employment_hourly_rate"
        type="text"
        :label="$t('label.employment_hourly_rate')" />
    <StyledInput
        v-model="form.employment_vacation_weeks_per_year"
        :placeholder="$t('label.employment_vacation_weeks_per_year')"
        name="employment_vacation_weeks_per_year"
        type="text"
        :label="$t('label.employment_vacation_weeks_per_year')" />
    <StyledMask
        v-model="form.employment_rrsp_percent"
        suffix="%"
        :placeholder="$t('label.employment_rrsp_percent')"
        :options="{ mask: '##.##' }"
        name="emerg_phone"
        :label="$t('label.employment_rrsp_percent')" />
    <StyledRoundSwitch
        v-model="form.staff_fund_contribution"
        color="primary"
        inset
        :placeholder="$t('label.staff_fund_contribution')"
        name="staff_fund_contribution"
        :label="$t('label.staff_fund_contribution')" />  
    <v-divider class="col-span-full mb-4" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{ $t('label.save') }}</StyledButton>
</div>
</template>
