// Vuetify
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

//import fcLight from './themes/fc-light';
import fabled from './themes/fabled';
import 'vuetify/styles';

import { fa } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';

export const vuetify = createVuetify({
    components,
    directives,
    styles: {
        configFile: 'src/css/settings.scss',
    },
    display: {
        mobileBreakpoint: 'sm',
        //Match TailwindCSS
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            '2xl': 1536
        },
    },
    icons: {
        defaultSet: 'mdi', // 'mdiSvg' || 'faSvg'
        sets: {
            fa,
            mdi,
        }
    },
    theme: {
        defaultTheme: 'fabled',
        themes: {
            fabled,
        },
    },
    defaults: {
        VAutocomplete: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact',
            hideNoData: true,
            chips: true,
            closableChips: true,
        },
        VCheckbox: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VDatePicker: {
            variant: 'outlined',
            clearable: true,
            color: 'accent',
            density: 'compact'
        },
        VFileInput: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VRadio: {
            color: 'primary',
        },
        VSwitch: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VTextarea: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VTextField: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VSelect: {
            variant: 'outlined',
            clearable: true,
            color: 'primary',
            density: 'compact'
        },
        VBtn: {
            variant: 'elevated',
            size: 'default',
            type: 'button',
            block: false,
            density: 'default',
            color: 'primary',
        },
        VIcon: {
            color: 'accent',
        },
    },
});
