<script setup>
import { ref, inject } from 'vue';
import { useClientStore } from '@/stores/client';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';

const { search } = useClientStore();
const { all } = storeToRefs(useClientStore());
const { clientTypes } = storeToRefs(useOptionsStore());

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    per_page: 10,
    type: null
});

const searchClients = () => {
    search({
        ...params.value,
    });
};

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchClients();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchClients();
};

const accessClient = (client) => {
    router.visit(`/clients/${client.hash}/details`);
};

const openModal = (modal, mode = 'Create', client = null) => {
    modals.show(modal, {
        props: client ? {
            client: client,
            mode: mode
        } : {
            mode: mode,
        }
    }).then(() => {
        searchClients();
    });
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.client_management') }}</template>
    <template #actions>
        <StyledButton @click="openModal('ClientsModal')"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_client') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchClients"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.type"
            :placeholder="$t('label.type')"
            :options="clientTypes"
            name="type"
            :label="$t('label.type')" 
            @input="searchClients" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'client_name', value: item => `${item.first_name} ${item.last_name} (${item.preferred_name})`, sortable: false },
            { title: $t('label.status'), key: 'client_status', value: item => `${item?.status?.name ?? ''}`, sortable: false },
            { title: $t('label.address'), key: 'address', value: item => `${item.address}`, sortable: false },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessClient(item),
            },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
